import React, { Component } from 'react';
import { Button, Dropdown, Form, Grid, Header, Icon, List } from 'semantic-ui-react';
import moment from 'moment';
import { timeConvert } from '../../../../utils/Methods';
import { stringify } from 'querystring';
import axios from 'axios';
import DatePicker, { registerLocale } from 'react-datepicker';
import { withTranslation } from 'react-i18next';
import tr from 'date-fns/locale/tr';
import en from 'date-fns/locale/en-GB';
import { showError, showInfo } from '../../../../utils/ToastHelpers';
import {
    getUserSummaryList,
    getReportDeviceUsageRawData,
    downloadDeviceUsagesAsZip,
    startReportDeviceUsageRawData,
    getAllRoles, checkAsyncTaskStatus
} from "../../../../api/apiCalls";
import ReactTableV8 from '../../../../components/ReactTableV8/ReactTableV8';
import { getPrivileges } from '../../../../utils/Methods'
import { BRAND_HEADER, chartsRawUsageDataMessages, DEVICE_HEADER, DEVICE_ID_HEADER, DEVICE_MODEL_HEADER, DOWNLOAD_TABLE_AS_CSV, END_DATE_LABEL, NOT_FETCH_DEVICES, NOT_FETCH_USERS, NO_LABEL, N_A_LABEL, OPERATING_SYSTEM_HEADER, OS_VERSION_HEADER, RESERVED_LABEL, SCREEN_RESOLUTION_HEADER, SCREEN_SIZE_HEADER, SEARCH_ID_PLACEHOLDER, SEARCH_USER_PLACEHOLDER, START_DATE_LABEL, TEST_TYPE_LABEL, TIME_LABEL, UNKNOWN_LABEL, USERNAME_HEADER, USERS_HEADER, USER_GROUPS_HEADER, YES_LABEL, NONE_LABEL, NOT_FETCH_ROLES_ERROR_MESSAGE,SEARCH_ROLE_PLACEHOLDER, TEST_INFO_LABEL } from '../../../../utils/UIMessages';
import { DOWNLOAD_ICON, MOBILE_ICON } from '../../../../utils/UiIcons';
import { USER_ROLES_LABEL } from '../../../../utils/UIMessages';
import { ASYNC_TASK_STATUS } from "../../../../utils/Constants";


registerLocale('tr', tr);
registerLocale('en', en);

class ChartsRud extends Component {
    
    //React Table V8 Columns
    columnsRender = () => [
        {
            header: () => <div>{USERNAME_HEADER()}</div>,
            accessorKey: 'userName',
            id: 'userName',
            cell: info => (<>{info.getValue() !== null
                ? <> <p> {info.row.original.fullName}<br /> <span style={{ color: 'gray', fontSize: 12 }}>{info.getValue()}</span>
                </p> </>
                : N_A_LABEL()}</>)
        },
        {
            header: () => <div>{USER_ROLES_LABEL()}</div>,
            accessorKey: 'userRoles',
            id: 'userRoles',
            cell: info => (<List as='ol' verticalAlign='middle'>{info.getValue()?.map((role, i) => {
                return (<List.Item key={i} as='li' value='*'>
                    {role}
                </List.Item>)
            })}</List>)
        },
        {
            header: () => <div>{USER_GROUPS_HEADER()}</div>,
            accessorKey: 'userGroups',
            id: 'userGroups',
            cell: info => (<span>{info.getValue()?.join(', ') ||  N_A_LABEL()}</span>)
        },
        {
            header: () => <div>{DEVICE_ID_HEADER()}</div>,
            accessorKey: 'udid',
            id: 'udid',
            cell: info => (<span>{info.getValue() ||  N_A_LABEL()}</span>)
        },
        {
            header: () => <div>{DEVICE_MODEL_HEADER()}</div>,
            accessorKey: 'deviceModel',
            id: 'deviceModel',
            cell: info => (<span>{info.getValue() ||  N_A_LABEL()}</span>)
        },
        {
            header: () => <div>{BRAND_HEADER()}</div>,
            accessorKey: 'brand',
            id: 'brand',
            cell: info => (<span>{info.getValue() ||  N_A_LABEL()}</span>)
        },
        {
            header: () => <div>{chartsRawUsageDataMessages().DEVICE_NAME_HEADER}</div>,
            accessorKey: 'deviceName',
            id: 'deviceName',
            cell: info => (<span>{info.getValue() ||  N_A_LABEL()}</span>)
        },
        {
            header: () => <div>{OPERATING_SYSTEM_HEADER()}</div>,
            accessorKey: 'os',
            id: 'os',
            cell: info => (<span>{info.getValue() ||  N_A_LABEL()}</span>)
        },
        {
            header: () => <div>{OS_VERSION_HEADER()}</div>,
            accessorKey: 'osVersion',
            id: 'osVersion',
            cell: info => (<span>{info.getValue() ||  N_A_LABEL()}</span>)
        },
        {
            header: () => <div>{SCREEN_SIZE_HEADER()}</div>,
            accessorKey: 'screenSize',
            id: 'screenSize',
            cell: info => (<span className='number'>{info.getValue() ?? UNKNOWN_LABEL()}</span>)
        },
        {
            header: () => <div>{SCREEN_RESOLUTION_HEADER()}</div>,
            accessorKey: 'screenResolution',
            id: 'screenResolution',
            cell: info => (<span>{info.getValue() ||  N_A_LABEL()}</span>)
        },
        {
            header: () => <div>{chartsRawUsageDataMessages().START_USAGE_HEADER}</div>,
            accessorKey: 'startUsage',
            id: 'startUsage',
            cell: info => (<>{info.getValue() !== null
                ? <> <p>{moment(info.getValue()).format('DD/MM/YYYY')} <br /> <span
                    style={{ color: 'gray', fontSize: 12 }}>{moment(info.getValue()).format('HH:mm:ss')}</span></p> </>
                :  N_A_LABEL()}</>)
        },
        {
            header: () => <div>{chartsRawUsageDataMessages().END_USAGE_HEADER}</div>,
            accessorKey: 'endUsage',
            id: 'endUsage',
            cell: info => (<>{info.getValue() !== null
                ? <> <p>{moment(info.getValue()).format('DD/MM/YYYY')} <br /> <span
                    style={{ color: 'gray', fontSize: 12 }}>{moment(info.getValue()).format('HH:mm:ss')}</span></p> </>
                :  N_A_LABEL()}</>)
        },
        {
            header: () => <div>{chartsRawUsageDataMessages().USAGE_TIME_HEADER}</div>,
            accessorKey: 'usageSec',
            id: 'usageSec',
            cell: info => (<span className='number'>{timeConvert(info.getValue()) === NONE_LABEL() ? <>
                {chartsRawUsageDataMessages().LESS_THAN_A_SECOND_LABEL}</> : <>{timeConvert(info.getValue())}</>}</span>)
        },
        {
            header: () => <div>{TEST_TYPE_LABEL()}</div>,
            accessorKey: 'testType',
            id: 'testType',
            cell: info => (<span>{this.props.t(info.getValue()) ||  N_A_LABEL()}</span>)
        },
        {
            header: () => <div>{RESERVED_LABEL()}</div>,
            accessorKey: 'reserved',
            id: 'reserved',
            cell: info => (<span
                className='number'>{info.getValue() ? <>{YES_LABEL()}</> : <>{NO_LABEL()}</>}
            </span>)
        },
        {
            header: () => <div>{TEST_INFO_LABEL()}</div>,
            accessorKey: 'testInfo',
            id: 'testInfo',
            cell: info => (<span>{info.getValue() ||  N_A_LABEL()}</span>)
        }
    ];

    state = {
        response: {},
        deviceUsages: [],
        //deviceUsagesURL: '/api/v1/reports/raw-data',
        page: 0,
        loading: false,
        pageSize: 10,
        totalPages: 10,
        allParams: {
            page: 0,
            size: 10
        },
        allUsers: [],
        endDate: new Date(),
        startDate: null,
        userName: "",
        udid: "",
        privileges: [],
        loadingDevices: false,
        loadingUsers: false,
        loadingRoles: false,
        roleDropdown: [],
        userRoles: '',
        disableDownloadButton: false
    };

    loadData = () => {
        this.setState({loading: true})
        let params = this.state.allParams
        Object.keys(params).forEach(key => {
            if (typeof params[key] === "string") {
                if (params[key].slice(-1) === ":" || params[key].includes("Invalid date")) {
                    delete params[key]
                }
            }
        })
        params = stringify(params)
        getReportDeviceUsageRawData(params).then(res => {
            this.setState({
                deviceUsages: res.data.content,
                pageSize: res.data.size,
                totalPages: res.data.totalPages,
                page: res.data.pageable.pageNumber,
                loading: false
            })
        })
    };

    onPaginationChange = (pageIndex, pageSize) => {
        this.setState({ pageSize })
        this.setState(prevState => ({
            allParams: {
                ...prevState.allParams,
                page: pageIndex,
                size: pageSize
            }
        }), () => this.loadData())
    };

    handleDropdown = (e, { value, name }) => {
        this.setState({ [name]: value })
        if(name  === 'userRoles'){
            this.setState(prevState => ({
                allParams: {
                    ...prevState.allParams,
                    [name]: value
                }
            }), () => this.loadData())
        } else {
            this.setState(prevState => ({
                allParams: {
                    ...prevState.allParams,
                    [name]: value
                }
            }), () => this.loadData())
        }
    }

    onChangeStartDate = (date) => {
        this.setState({startDate: date}, () => {
            date = moment(date).format("YYYY-MM-DDTHH:mm:ss");
            this.setState(prevState => ({
                allParams: {
                    ...prevState.allParams,
                    "startUsage": "gte:" + date
                }
            }), () => this.loadData())
        })
    };


    onChangeEndDate = (date) => {
        this.setState({endDate: date}, () => {
            date = moment(date).format("YYYY-MM-DDTHH:mm:ss");
            this.setState(prevState => ({
                allParams: {
                    ...prevState.allParams,
                    "endUsage": "lte:" + date
                }
            }), () => this.loadData())
        })
    };


    onClickCsvDownload = () => {
        let params = this.state.allParams
        Object.keys(params).forEach(key => {
            if (typeof params[key] === "string") {
                if (params[key].slice(-1) === ":" || params[key].includes("Invalid date")) {
                    delete params[key]
                }
            }
        })
        delete params.page
        delete params.size
        startReportDeviceUsageRawData(params).then(res=> {
            showInfo(chartsRawUsageDataMessages().DOWNLOAD_OPERATION_STATUS_INFO,5000);
            const idToken = res.data?.taskId;
            const downloadStatus = res.data?.downloadStatus;
            if(downloadStatus === ASYNC_TASK_STATUS.INITIALIZED
              || downloadStatus === ASYNC_TASK_STATUS.PROCESSING){
                this.setState({ disableDownloadButton: true});
                const downloadStatusCheck = setInterval(() =>
                  checkAsyncTaskStatus(idToken).then(res=> {
                    if(res.data.status === ASYNC_TASK_STATUS.COMPLETED){
                        clearInterval(downloadStatusCheck);
                        showInfo(chartsRawUsageDataMessages().EXPORT_RAW_USAGE_DATA_COMPLETED_INFO_MESSAGE,5000);
                        this.setState({ disableDownloadButton: false});
                        downloadDeviceUsagesAsZip(params,idToken).then(res => {
                            const link = document.createElement('a');
                            link.href = window.URL.createObjectURL(new Blob([res.data], {type: 'application/zip'}));
                            link.setAttribute('download', 'Report.zip');
                            document.body.appendChild(link);
                            link.click();
                            link.remove();
                        }).catch(err => {
                            showError(chartsRawUsageDataMessages().NOT_DOWNLOAD_REPORTS_ERROR_MESSAGE + err?.response?.data?.message ?? err.toString())
                        })
                    }
                }), 10000);
            }
        })
    };

    getUsages = () => {
        let {startDate} = this.state
        startDate = new Date().setMonth(new Date().getMonth() - 1)
        this.setState({startDate, loading: true});
        getReportDeviceUsageRawData(stringify({page: 0, size:10})).then(res => {
            this.setState({
                deviceUsages: res.data.content,
                pageSize: res.data.size,
                totalPages: res.data.totalPages,
                page: res.data.pageable.pageNumber,
                allParams: {page: res.data.pageable.pageNumber, size: res.data.size}
            })
        }).catch(err => {
            showError(chartsRawUsageDataMessages().NOT_FETCH_DEVICE_USAGE+ err?.response?.data?.message ?? err.toString())
        }).finally(() => this.setState({ loading: false }));
    }

    onSortChange = newSorted => {
        if (newSorted.length > 0) {
            const columnName = newSorted[0].id
            const orderBy = newSorted[0].desc ? 'DESC' : 'ASC'
            this.setState(prevState => ({
                allParams: {
                    ...prevState.allParams,
                    'sort': `${columnName},${orderBy}`
                }
            }), () => this.loadData())
        }
    }

    threeMonthsFilter = (date, type) => {
        const filtered = new Date(date)
        if (type === "start") {
            return new Date(filtered.setMonth(filtered.getMonth() - 3))
        }
        if (type === "end") {
            return new Date(filtered.setMonth(filtered.getMonth() + 3))
        }
    }


    componentDidMount() {
        const privileges = getPrivileges();
        this.setState({privileges})
        this.getUsages()
        this.setState({ loadingUsers: true });
        //axios.get("/api/user/users")
        getUserSummaryList().then((res) => {
            let allUsers = res.data.sort(function compare(a, b) {
                const bandA = a.fullName?.toUpperCase();
                const bandB = b.fullName?.toUpperCase();

                let comparison = 0;
                if (bandA > bandB) {
                    comparison = 1;
                } else if (bandA < bandB) {
                    comparison = -1;
                }
                return comparison;
            })
            allUsers = allUsers.map((user) => {
                return {
                    key: user.userName,
                    value: user.userName,
                    text: user.fullName === null ? user.userName : user.fullName,
                }
            });
            this.setState({allUsers})
        }).catch(err => {
            showError(NOT_FETCH_USERS()+ err?.response?.data?.message ?? err.toString())
        }).finally(() => this.setState({ loadingUsers: false }));
        this.setState({ loadingDevices: true });
        axios.get("/api/devices").then(res => {
            let allDeviceIds = res.data.map(device => {
                return {
                    key: device.deviceId,
                    value: device.deviceId,
                    text: device.deviceId,
                    content: (<Header size="tiny" icon={MOBILE_ICON} content={device.deviceId}
                                      subheader={`${device.brand} ${device.deviceModel}`}/>)
                };
            });
            this.setState({allDeviceIds})
        }).catch(err => {
            showError(NOT_FETCH_DEVICES()+ err?.response?.data?.message ?? err.toString())
        }).finally(() => this.setState({ loadingDevices: false }));
       this.getAllRoles();
    }

    getAllRoles = () => {
        this.setState({ loadingRoles: true });
        getAllRoles().then(res2 => {
            let roleDropdown = res2.data.map(role => {
                return {
                    key: role.roleId,
                    value: role.name,
                    text: role.name,
                }
            })
            this.setState({roleDropdown})
        }).catch(err => {
            showError(NOT_FETCH_ROLES_ERROR_MESSAGE() + err?.response?.data?.message ?? err.toString())
        }).finally(() => this.setState({ loadingRoles: false }));
    }

    render() {
        return (
            <div>
                <div>
                    <Grid>
                        <Grid.Row columns={1} style={{marginTop: -75, paddingRight:12}}>
                            <Grid.Column>
                                <Form style={{display: "flex", justifyContent: "center"}}>
                                    <Form.Group>
                                    <Form.Field>
                                            <label>{START_DATE_LABEL()}</label>
                                            <DatePicker
                                                selected={this.state.startDate}
                                                onChange={date => {
                                                    this.onChangeStartDate(date)
                                                }}
                                                minDate={this.threeMonthsFilter(this.state.endDate, "start")}
                                                maxDate={new Date()}
                                                selectsStart
                                                startDate={this.state.startDate}
                                                endDate={this.state.endDate}
                                                dateFormat="dd/MM/yyyy HH:mm"
                                                showTimeSelect
                                                timeFormat="HH:mm"
                                                timeIntervals={15}
                                                timeCaption={TIME_LABEL()}
                                                locale={this.props.i18n.language}
                                            />
                                        </Form.Field>
                                        <Form.Field>
                                            <label>{END_DATE_LABEL()}</label>
                                            <DatePicker
                                                selected={this.state.endDate}
                                                onChange={date => {
                                                    this.onChangeEndDate(date)
                                                }}
                                                selectsEnd
                                                maxDate={this.threeMonthsFilter(this.state.startDate, "end")}
                                                startDate={this.state.startDate}
                                                endDate={this.state.endDate}
                                                minDate={this.state.startDate}
                                                dateFormat="dd/MM/yyyy HH:mm"
                                                showTimeSelect
                                                timeFormat="HH:mm"
                                                timeIntervals={15}
                                                timeCaption={TIME_LABEL()}
                                                locale={this.props.i18n.language}
                                            />
                                        </Form.Field>
                                        <Form.Field>
                                            <label>{USERS_HEADER()}</label>
                                            <Dropdown className='report-dropdown' fluid search
                                                      selection clearable
                                                      placeholder={SEARCH_USER_PLACEHOLDER()}
                                                      name={'userName'}
                                                      options={this.state.allUsers}
                                                      value={this.state.userName} onChange={this.handleDropdown}
                                                      loading={this.state.loadingUsers} />
                                        </Form.Field>
                                        <Form.Field>
                                            <label>{DEVICE_HEADER()}</label>
                                            <Dropdown className='report-dropdown' fluid selection
                                                      search clearable
                                                      name={'udid'}
                                                      placeholder={SEARCH_ID_PLACEHOLDER()}
                                                      options={this.state.allDeviceIds}
                                                      value={this.state.udid} onChange={this.handleDropdown}
                                                      loading={this.state.loadingDevices} />
                                        </Form.Field>
                                        <Form.Field>
                                            <label>{USER_ROLES_LABEL()}</label>
                                            <Dropdown className='report-dropdown' fluid selection
                                                      search clearable
                                                      name='userRoles'
                                                      placeholder={SEARCH_ROLE_PLACEHOLDER()}
                                                      options={this.state.roleDropdown}
                                                      value={this.state.userRoles} onChange={this.handleDropdown}
                                                      loading={this.state.loadingRoles} />
                                        </Form.Field>                                    
                                    </Form.Group>
                                </Form>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row centered columns={1}>
                            <Grid.Row centered columns={1}>
                                <Grid.Column>
                                    <ReactTableV8
                                        //Minimum requirements for the table
                                        data={this.state.deviceUsages}
                                        columns={this.columnsRender()}

                                        //Server-side sorting
                                        onSortedChangeProp={newSorted => this.onSortChange(newSorted)}
                                        manualSortingProp={true}

                                        //Backend-side controlled pagination parameters---
                                        pageIndexProp = {this.state.page}
                                        pageSizeProp= {this.state.pageSize}
                                        totalPagesProp = {this.state.totalPages}
                                        onPaginationChangeProp={this.onPaginationChange}
                                        manualPaginationProp = {true}

                                        //Loading Status
                                        loadingProp={this.state.loading}
                                    />

                                    <Button
                                        icon
                                        floated='right'
                                        color='green'
                                        style={{marginTop: 5, marginRight: 10}}
                                        onClick={this.onClickCsvDownload}
                                        disabled={this.state.disableDownloadButton}
                                    > <Icon
                                        name={DOWNLOAD_ICON}/> {DOWNLOAD_TABLE_AS_CSV()}</Button>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid.Row>
                    </Grid>
                </div>
            </div>
        );
    }
}

export default withTranslation()(ChartsRud);
