import i18n from '../translate/i18n';
import { secondsToDhms } from './Methods';
const t = (str) => i18n.t(str);

// it is used in components/chat -> Chat.js,ChatInput.js,ChatPortal.js
export const chatMessages = () => {
  return {
    PLACEHOLDER: t('Type your message...'),
    ERROR_MESSAGE: t('Can not fetch chat messages from server. Error')
  };
};
//it is used for components/ReactTableV8/Filter.js
export const filterMessages = () => {
  return {
    DROPDOWN_PLACEHOLDER: t('Select')
  };
};

//it is used for components/ReactTableV8/ReactTableV8.js
export const reactTableMessages = () => {
  return {
    DEBOUNCE_INPUT_SEARCH_ALL_PLACEHOLDER: t('Search all columns...')
  };
};

//it is used for components/ActionsPanel.js
export const actionsMessages = (statusCode, message, data, itemName) => {
  return {
    ACTION_GROUP_OPTIONS_EXEC_MOBILE_COMMAND: t('Execute Mobile Command'),
    ACTION_GROUP_OPTIONS_CONTEXT: t('Context'),
    ACTION_GROUP_OPTIONS_WEB: t('Web'),
    ACTION_GROUP_OPTIONS_W3: t('Window (W3)'),
    SESSION_SUB_ACTION_GROUP_OPTIONS_SESSION_CAPABILITIES: t('Session Capabilities'),
    SESSION_SUB_ACTION_GROUP_OPTIONS_TIMEOUTS: t('Timeouts'),
    SESSION_SUB_ACTION_GROUP_OPTIONS_ORIENTATION: t('Orientation'),
    SESSION_SUB_ACTION_GROUP_OPTIONS_GEOLOCATION: t('Geolocation'),
    SESSION_SUB_ACTION_GROUP_OPTIONS_LOGS: t('Logs'),
    SESSION_SUB_ACTION_GROUP_OPTIONS_SETTINGS: t('Settings'),
    DEVICE_SUB_ACTION_GROUP_OPTIONS_SYSTEM: t('System'),
    DEVICE_SUB_ACTION_GROUP_OPTIONS_CLIPBOARD: t('Clipboard'),
    DEVICE_SUB_ACTION_GROUP_OPTIONS_NETWORK: t('Network'),
    DEVICE_SUB_ACTION_GROUP_OPTIONS_INTERACTION: t('Interaction'),
    DEVICE_SUB_ACTION_GROUP_OPTIONS_KEYS: t('Keys'),
    DEVICE_SUB_ACTION_GROUP_OPTIONS_ACTIVITY: t('Activity'),
    DEVICE_SUB_ACTION_GROUP_OPTIONS_APP: t('App'),
    DEVICE_SUB_ACTION_GROUP_OPTIONS_PERFORMANCE_DATA: t('Performance Data'),
    WEB_SUB_ACTION_GROUP_OPTION_NAVIGATE: t('Navigate'),
    EXEC_MOBILE_COMMAND_ACTION_GROUP: t('Execute'),
    SHOW_RESULT_MESSAGE: t('Command was returned with result HTTP %s - %s. %s')
      .replace('%s', statusCode)
      .replace('%s', message)
      .replace('%s', JSON.stringify(data)),
    EXECUTE_ACTION_BUTTON: t('Execute Action'),
    BUTTON_NAME_LABEL: t(itemName)
  };
};

//it is used for components/AnnouncementsModal.js
export const announcementMessages = () => {
  return {
    NOT_FETCH_NON_READ_ANNOUNCEMENTS: t('Error while fetching Nonread Announcements!'),
    HISTORY_ERROR: t('Error while fetching announcement history!'),
    AWAITING_ERROR: t('In-Process announcements could not be fetched!'),
    DELETE_SUCCESSFULL: t('Announcement has been deleted successfully!'),
    DELETE_ERROR: t('Error occured while deleting the announcement!'),
    UPDATE_SUCCESSFULL: t('Announcement has been updated successfully!'),
    UPDATE_ERROR: t('Error while updating the announcement!'),
    NEW_ANNOUNCEMENT_SUCCESS: t('New announcement has been published successfully!'),
    NEW_ANNOUNCEMENT_ERROR: t('Error: New announcement couldn\'t be published!'),
    NO_RPIV_TO_EDIT_GROUPLESS_TARGET: t('You do not have privilege to edit target for Groupless Users!'),
    POPUP_WARNING_ONLY_YOUR_GROUPS_CAN_BE_SELECTED: t('You can only select groups you belong to because you do not have Group Management privilege.'),
    NOT_FETCH_PUBLISHING_ANNOUNCEMENTS: t('Error while fetching publishing announcements!'),
    TIME_INTERVAL_HEADER: t('Time Interval'),
    PLACEHOLDER_SELECT_TARGET_GROUPS: t('Select Target Group(s)'),
    TARGET_GROUP_LABEL: t('Target Group'),
    NO_ANNOUNCEMENT: t('It seems empty'),
    AWAITING_TAB: t('Awaiting'),
    HISTORY_TAB: t('History'),
    LIVE_TAB: t('Live'),
    DETAILS_EDIT_POPUP: t('Details/Edit'),
    ANNOUNCEMENT_BY_ADMIN: t('Announcements by Admin'),
    CREATE_NEW_ANNOUNCEMENT: t('Create New Announcement'),
    UPDATE_ANNOUNCEMENT: t('Update Announcement'),
    NAME: t('Announcement Name'),
    DESCRIPTION: t('Announcement Description'),
    NAME_PLACEHOLDER: t('Enter the announcement name here.'),
    DESCRIPTION_PLACEHOLDER: t('Describe the details of the announcement here.'),
    DELETE_CONFIRM: t('Are you sure you want to delete this announcement?'),
    USER_DETAILS_NOT_FOUND : t('User details cannot be fetched!'),
    EDT_LABEL: t('Edt'),
    START_ABBR: t('St'),
    END_ABBR: t('Ed'),
    ANNOUNCEMENTS_LABEL: t('Announcements'),
    PUBLIC_LABEL: t('Public'),
    DELETED_BY_LABEL: t('Deleted by'),
    MARK_READ_POPUP: t('Mark as Read'),
    MARK_UNREAD_POPUP: t('Mark as Unread'),
    IN_PROCESS_LABEL: t('In-Process'),
    COMPLETED_LABEL: t('Completed on time'),
    GROUPLESS_LABEL: t('Groupless Users'),
    NEW_LABEL_UNREAD: t('New'),
    LASTLY_EDITED_BY: t('This announcement was lastly edited by'),
    READ_SUCCESSFUL: t('Successful! Announcement has been marked as read!'),
    READ_ERROR: t('Unsuccessful! Announcement could not be marked as read!'),
    UNREAD_SUCCESFULLY: t('Successful! The announcement has been set as unread.'),
    UNREAD_ERROR: t('Unsuccessful! The announcement could not be set as unread!'),
    NO_PRIV_EDIT_TARGETNESS_OF_OTHER_GROUPS: t('You do not have privilege to edit target for other groups you do not belong to!'),
    OPERATION_WILL_BE_VISIBLE_IN_5_MINUTES: t('Operation is In Progress. Result can be visible after 5 minutes.'),
    USER_GROUPS_COULD_NOT_BE_FETCHED: t('User groups could not be fetched!')
  }
}

//it is used for components/ShowMoreDiaglog.js
export const showMoreDialogBoxMessages = () => {
  return {
    SHOW_MORE: t('Show More'),
    INPUT_NULL_OR_UNDEFINED: t('Input is null or undefined!'),
    UNSUPPORTED_SHOW_MORE_INPUT: t('Unsupoorted Input Type for Dialog Box, supporting: String, number, array and object.'),
  }
}

//it is used for components/AppsInDeviceModal.js
export const appsInDeviceMessages = () => {
    return {
        PACKAGE_NAME_HEADER: t('Package Name'),
        DELETE_APP_POPUP: t('Delete this app from this device.'),
        TERMINATE_APP_POPUP: t('Terminate this application.'),
        FETCH_ERROR: t('Can\'t fetch apps in this device. Error:'),
        DELETE_CONFIRM: t('Are you sure you want to delete this app from this device?'),
        TERMINATE_CONFIRM: t('Are you sure you want to terminate this application?'),
        APPLICATION_STOPPED: t('Application has been stopped'),
        APPLICATION_COULD_NOT_BE_STOPPED: t('Application could not be killed. Maybe already killed.')
    };
}

//it is used for components/AppUploadModal.js
export const appUploadMessages = () => {
  return {
    NEW_CATEGORY_LABEL: t('New Category'),
    APPLICATION_VERSION_NAME_LABEL: t('Application Version Name'),
    APPLICATION_FILE_LABEL: t('Application File'),
    RESIGN_APPLICATION_LABEL: t('Resign Application'),
    UPLOAD_APP_BUTTON: t('Upload App'),
    ANNOUNCE_NEW_APP_LABEL: t('Announce to Users'),
    QUESTION_MARK_ANNOUNCE_NEW_APP: t('You can announce new application to related users. When you activate this functionality, new announcement form will open after uploading operation.'),
    INJECT_APP_LABEL: t('Hardware Simulation'),
    QUESTION_MARK_INJECT_APP: t('You can simulate some hardware sensors when manual test session (Note This feature is experimental)')
  }
}

//it is used for components/CancelReservationModal.js
export const cancelReservationMessages = () => {
  return {
    CANCELATION_REASON_ERROR: t("Can't fetch cancelation reasons. Error Code"),
    CANCEL_RESERVATION_SUCCESSFULL: t('Reservation cancelled successfully'),
    CANCEL_RESERVATION_ERROR: t('Could not cancel reservation. Error'),
    MODAL_HEADER: t('Cancel Reservation'),
    CANCEL_REASON_LABEL: t('Select Cancel Reason')
  };
};

//it is used for components/ChangePasswordInput.js
export const changePasswordInputMessages = () => {
  return {
    PLACEHOLDER_DISABLED: t('Change Password'),
    PLACEHOLDER_NOT_DISABLED: t('New Password')
  };
};

//it is used for components/DateRangeDropdown.js
export const dateRangeDropdownMessages = () => {
  return {
    SIZE_LABEL: t('Size'),
    ORDER_BY_LABEL: t('Order By'),
    EXCLUDE_USER_LABEL: t('Exclude User'),
    SELECT_USERS_PLACEHOLDER: t('Select Users')
  };
};

//it is used for components/DeviceReservationInfo.js
export const deviceReservationInfoMessages = () => {
  return {
    TIME_OBJECT_WHEN_PAST: t('Past'),
    TIME_OBJECT_WHEN_FUTURE: t('Future'),
    TIME_OBJECT_WHEN_CURRENT: t('Current'),
    TIME_OBJECT_DAY_YESTERDAY: t('Yesterday'),
    TIME_OBJECT_DAY_TOMORROW: t('Tomorrow'),
    FROM_LABEL: t('From'),
    TO_LABEL: t('To'),
    RESERVE_THIS_DEVICE: t('Reserve This Device')
  };
};

//it is used for components/DeviceSidebar.js
export const deviceSidebarMessages = () => {
  return {
    LAST_USED_DEVICE_MENU_HEADER: t('Last Used Devices'),
    FAVORITE_DEVICES_MENU_HEADER: t('Favorite Devices')
  };
};

//it is used for components/EditDeviceFields.js
export const editDeviceFieldMessages = () => {
  return {
    CATEGORY_NAME_WHITESPACE_ERROR_MESSAGE: t('Category name cannot contain whitespace characters'),
    CATEGORY_NAME_NOT_ALLOWED_ERROR_MESSAGE: t("'and', 'or', 'not' cannot be used as category names"),
    NO_ADD_NEW_CATEGORY_ERROR_MESSAGE: t('Failed to add new category'),
    NOT_GET_CATEGORIES_ERROR_MESSAGE: t('Failed to get categories'),
    NOT_ADD_CATEGORY_TO_DEVICE_ERROR_MESSAGE: t('Failed to add category to device'),
    NOT_DELETE_CATEGORY_ERROR_MESSAGE: t('Failed to delete category'),
    DEVICE_IMAGE_LABEL: t('Device Image'),
    MARKET_NAME_LABEL: t('Market Name'),
    REMOVE_DEVICE_IMAGE_MESSAGE: t('Remove device image'),
    MARKET_NAME_NOT_VALID_MESSAGE: t('Market name is not valid'),
    DEVICE_TAG_NOT_VALID_MESSAGE: t('Device tag is not valid'),
    DEVICE_CATEGORY_ADDITIONAL_LABEL: t('Press Enter to add'),
    ADD_TO_DEVICE_BUTTON: t('Add To Device'),
    DELETE_CATEGORY_CONFIRM_HEADER: t('Delete Categories'),
    DELETE_CATEGORY_CONFIRM: t('Are you sure you want to delete the selected categories from database?'),
    PLEASE_SELECT_AN_IMAGE_MESSAGE: t('Please select an image'),
    BOOKABLE_LABEL: t('Bookable'),
    RESERVABLE_LABEL: t('Reservable'),
    GROUP_RESERVATION_DROPDOWN_PLACEHOLDER: t('Allowed Groups For Reservation'),
    MODAL_HEADER: t('Edit Device Properties'),
    DELETE_THIS_DEVICE_BUTTON: t('Delete This Device'),
    DELETE_DEVICE_LABEL: t('Delete Device'),
    DELETE_CONFIRM_MESSAGE: t('Are you sure you want to delete this device?'),
    PERMANENT_DELETE_BUTTTON: t('Permanently Delete Selected Categories')
  };
};

//it is used for components/FileDetailsModal.js
export const fileDetailsMessages = () => {
  return {
    DELETE_SUCCESSFULL: t('File deleted successfully'),
    FILE_DETAILS: t('File Details'),
    CONFIRM_DELETE: t('Are you sure you want to delete this file?'),
    CONFIRM_HEADER: t('Delete File'),
    FILE_NOT_FOUND_CONFIRM_HEADER: t('Warning'),
    FILE_NOT_FOUND_CONFIRM_DELETE: t(
      'File cannot be found in the storage to download! Do you want to delete its information from database?'
    )
  };
};

//it is used for components/FileEditModal.js
export const fileEditMessages = () => {
  return {
    HEADER_NAME: t('Edit Name')
  };
};
//it is used for components/FileUploadModal.js
export const fileUploadMessages = () => {
  return {
    UPLOAD_FILE_BUTTON: t('Upload File')
  };
};

//it is used for components/FilterBar.js
export const filterBarMessages = () => {
  return {
    FILTER_ERROR: t('Devices could not filtered. Error '),
    APP_LIST_NOT_PARSED_ERROR: t('Application list could not parsed. Error '),
    APP_LIST_FETCH_ERROR: t('Application list could not fetched. Error Code '),
    USAGE_STATUS_TITLE: t('Usage Status'),
    FAVORITES_TITLE: t('Favorites'),
    DEVICE_CATEGORIES_TITLE: t('Device Categories'),
    ALL_VERSIONS_LABEL: t('All Versions'),
    AVAILABLE_DEVICES_LABEL: t('Available Devices')
  };
};
//it is used for components/InformationPanel.js
export const informationPanelMessages = () => {
  return {
    OPEN_LABEL: t('Open'),
    CLOSED_LABEL: t('Closed'),
    DEVICE_DETAILS_NOT_FETCH: t('Can not fetch device details'),
    DEVICE_SPECS_NOT_FETCH: t('Unable to fetch device specs. Details '),
    WIFI_STATUS_HEADER: t('Wifi Status'),
    GPS_STATUS_HEADER: t('GPS Status'),
    GROUPS_HEADER: t('Groups'),
    LIST_OF_GROUPS: t('List of Groups')
  };
};

//it is used for components/InstantUsageInfo.js, InstantUsage.js
export const instantUsageMessages = (username, deviceId, usageType) => {
  return {
    USED_BY: t('Used By'),
    RESERVED_BY: t('Reserved By'),
    AUTOMATION_POPUP_MESSAGE: t('Stop Automation Test on This Device'),
    DEVELOPMENT_POPUP_MESSAGE: t('Stop Development Test on This Device'),
    POPUP_MESSAGE: t("Terminate %s's connection.").replace('%s', username),
    AUTOMATION_STOP_INFO: t('This device trying to remove from the grid.. Device Id: %s').replace('%s', deviceId),
    DEVELOPMENT_STOP_INFO: t('Trying to terminate the development test season.. Device Id: %s').replace('%s', deviceId),
    CONFIRM_TERMINATE: t("Are you sure you want to terminate user %s's connection?").replace('%s', username),
    REFRESH_TABLE_POPUP: t('Refresh Table'),
    USAGE_TYPE_LABEL: t(usageType)
  };
};

//it is used for components/LibraryFileUpload.js
export const libraryFileUploadMessages = () => {
  return {
    TOTAL_NUMBER_OF_FILES_LABEL: t('Total Number of Files')
  };
};

//it is used for components/LicenseModal.js
export const licenseMessages = () => {
  return {
    SUCCESSFUL: t('Success, Please login again.'),
    HEADER: t('License Not Found'),
    UPLOAD_LICENSE_HEADER: t('Please upload a license file to login.'),
    DEVICE_ID_HEADER: t('Your device ID'),
    NEW_LICENSE_HEADER: t('New License')
  };
};

//it is used for components/NotFoundPage.js
export const notFoundMessages = () => {
  return {
    NOT_FOUND_404: t('404'),
    MESSAGE: t('Page not found or you do not have permission to view it')
  };
};

//it is used for components/ReservationModal.js
export const reservationMessages = (updateReason) => {
  return {
    RESERVATION_TYPE_ERROR: t('Failed to get reservation types.'),
    SELECT_USER_LABEL: t('Select User'),
    OPTIONAL_LABEL: t('Optional'),
    SELECT_GROUP_PLACEHOLDER: t('Select Group'),
    RESERVATION_TYPE_HEADER: t('Reservation Type'),
    RESERVATION_TYPE_PLACEHOLDER: t('Select Reservation Type'),
    AGENDA_LABEL: t('Agenda'),
    NO_EVENT_IN_RANGE: t('There is no event in this range.'),
    FUTURE_DATE_ERROR: t('You must select future date. Error Code'),
    RESERVATION_DETAILS_FETCH_ERROR: t('Can not fetch reservation details. Error Code:'),
    DELETED_LABEL: t('Deleted'),
    REASON_LABEL: t('Reason'),
    UPDATE_REASON_LABEL: t(updateReason)
  };
};

//it is used for components/SelectedElementPanel.js
export const selectedElementPanelMessages = () => {
  return {
    TAP_BUTTON: t('Tap'),
    FIND_BY_LABEL: t('FindBy'),
    ATTRIBUTE_LABEL: t('Attribute')
  };
};

//it is used for components/SharedUrlExpirationModal.js
export const sharedUrlExpirationMessages = () => {
  return {
    MODAL_HEADER: t('Change Screen Share Time'),
    UNSUPPORTED_TOKEN_TYPE_ERROR: t('Unsupported Token Type'),
    LINK_TYPE_LABEL: t('Select Link Type'),
    ONE_TIME_LABEL: t('One Time'),
    SPECIFIED_TIME_LABEL: t('Specified Time'),
    EXPIRY_DATE_LABEL: t('Expiry Date'),
    ONE_TIME_SCREEN_SHARE_LABEL: t('One Time Screen Share'),
    SPECIFIED_TIME_SCREEN_SHARE_LABEL: t('Specified Time Screen Share')
  };
};

//it is used for components/SharedUrlModal.js
export const sharedUrlMessages = () => {
  return {
    REFRESH_TOKEN_POPUP: t('Refresh Token'),
    CHANGE_LINK_EXPIRATION_POPUP: t('Change Link Expiration'),
    MODAL_HEADER: t('Embedded Screen Links'),
    VIEW_ONLY_SCREEN_LABEL: t('View Only Screen'),
    MANAGE_SCREEN_LABEL: t('Manage Screen'),
    INSPECTOR_SCREEN_LABEL: t('Inspector Screen')
  };
};

//it is used for components/SidebarFavDevices.js
export const sidebarFavDevicesMessages = () => {
  return {
    FAVORITE_DEVICES_FETCH_ERROR: t('Could not fetch favorite devices. Error:')
  };
};

//it is used for components/SidebarLastDevices.js
export const sidebarLastDevicesMessages = () => {
  return {
    LAST_DEVICES_FETCH_ERROR: t('Could not fetch last used devices. Error:')
  };
};
//it is used for components/TimedOutModal.js
export const timedOutMessages = (fullTime) => {
  return {
    MODAL_HEADER: t('Connection Timed Out'),
    CONNECTION_CLOSED_MESSAGE: t('Your connection is closed due to inactivity for %s').replace(
      '%s',
      secondsToDhms(fullTime)
    )
  };
};

//it is used for components/TwoFactorAuthenticationModal.js
export const twoFactorAuthMessages = () => {
  return {
    MODAL_HEADER: t('Authentication PIN'),
    MODAL_DESCRIPTION: t('Check Your Authenticator Application For Time Based Authentication PIN'),
    RESET_TWO_FACTOR_AUTH_REGISTRATION_SECRET: t('Reset Two Factor Authentication Registration Secret'),
    VERIFY_BUTTON: t('Verify')
  };
};

//it is used for components/WarningModal.js
export const warningMessages = (fullTime) => {
  return {
    MODAL_HEADER: t('Warning'),
    INACTIVE_ERROR_MESSAGE: t('You have been inactive for %s').replace('%s', secondsToDhms(fullTime)),
    TERMINATE_MODAL_CONNECTION: t('Your connection will be terminated after')
  };
};

//it is used for pages/app/AppDetailsModal.js
export const appDetailsMessages = () => {
  return {
    APP_VERSION_HEADER: t('App Version'),
    APP_VERSION_CODE_HEADER: t('App Version Code'),
    APP_VERSION_NAME_HEADER: t('App Version Name'),
    APP_DETAILS_HEADER: t('Application Details'),
    APP_CATEGORY_PLACEHOLDER: t('Select Application Category'),
    DELETE_APP_HEADER: t('Delete Application'),
    DELETE_SELECTED_APPS_LABEL: t('Delete Selected Applications'),
    DELETE_CONFIRM: t('Are you sure you want to delete this application?'),
    DELETE_SELECTED_APP_CONFIRM: t('Are you sure you want to delete the selected applications?')
  };
};

//it is used for pages/app/Library.js
export const libraryMessages = (errMsg) => {
  return {
    NOT_FETCH_APP_CATEGORIES: t('Cant fetch App Categories.'),
    REQUEST_CANCELED_ERROR: t('Request canceled. Error : '),
    APP_UPLOAD_ANNOUNCEMENT_DESCRIPTION_MSG: t('A New Application Uploaded to Farm Library!'),
    SHOW_PREPARE_FILE_UPLOAD: t('Preparing for file upload'),
    SHOW_START_FILE_UPLOAD: t('File upload started please wait for finish'),
    SHOW_SUCCESS_FILE_UPLOAD: t('Success! Application File has been uploaded to library.'),
    SHOW_ERROR_FILE_UPLOAD: t('An error occurred while File upload to library!'),
    START_INJECTION_TASK: t('App prepared for hardware simulation please wait.'),
    READY_INJECTION_TASK: t('App ready for hardware simulation.'),
    ERROR_INJECTION_TASK: t('An error occurred while preparing the hardware simulation!'),
    ERROR_UPLOAD_CHUNK: t('Error occurred during upload chunk'),
    ERROR_MESSAGE: t(errMsg)
  };
};

//it is used for pages/device/DeviceClipboard.js
export const deviceClipboardMessages = () => {
  return {
    COPIED_AS_TXT_FORMAT: t('Device Information Copied as Text format'),
    COPIED_AS_JSON_FORMAT: t('Device Information Copied as JSON format')
  };
};

//it is used for pages/deployment/DepAppList.js
export const depAppListMessages = () => {
  return {
    SELECT_APP_LABEL: t('Select Application'),
    SELECT_VERSION_LABEL: t('Select Version'),
    SELECT_FILE_LABEL: t('Select File'),
    LOCAL_LABEL: t('Local'),
    POPUP_MESSAGE: t(
      "The list shows you the last uploaded 20 files. If you don't see the file you are looking for in the list, type in by name."
    ),
    DROPDOWN_PLACEHOLDER: t('Select or Search File Here'),
    DROPDOWN_NO_RESULT: t('No Result Found'),
    DEBOUNCE_PLACEHOLDER: t('Search by name...'),
    MANUAL_BUSY_LABEL: t('Manual Busy'),
    AUTOMATION_BUSY_LABEL: t('Automation Busy'),
    TAG_HEADER: t('Tag'),
    BUILD_LABEL: t('build'),
    SELECTED_DEVICES_LABEL: t('Selected Devices'),
    SELECTED_DEVICE_FROM_TABLE_MESSAGE: t('Select devices from the table'),
    CLEAR_ALL_DEVICES_BUTTON: t('Clear All Devices')
  };
};
//it is used for pages/device/DeviceListCard.js
export const deviceListCardMessages = () => {
  return {
    DEVICE_ID_COPIED_MESSAGE: t('Device Id Copied!'),
    UNKNOWN_MODEL_LABEL: t('Unknown Model'),
    USE_DATE_LABEL: t('Use Date'),
    CANCEL_OFFLINE_RESERVATION: t('Cancel Offline Reservation'),
    INFO_LABEL: t('Info')
  };
};
//it is used for pages/device/DeviceListPage.js
export const deviceListPageMessages = () => {
  return {
    DEVICE_PICTURE_FETCH_ERROR: t('Device pictures could not be fetch from the server. Error '),
    DEVICE_NOT_SET_UNFAVORITE_MESSAGE: t('Device could not set unfavorite '),
    DEVICE_NOT_SET_FAVORITE_MESSAGE: t('Device could not set as favorite '),
    RESERVATION_GROUP_NOT_REACHED: t('Reservation group could not be reached'),
    CONNECT_TO_DEVICES_BUTTON: t('Connect to Devices'),
    CLEAR_FILTERS_BUTTON: t('Clear Filters')
  };
};

//it is used for pages/device/DeviceListTable.js
export const deviceListTableMessages = (deviceId) => {
  return {
    STOP_TEST_INFO: t('Trying to remove from the grid.. Device Id: %s').replace('%s', deviceId),
    MANAGE_POPUP: t('Manage'),
    STOP_AUTOMATION_TEST_POPUP: t('Stop Automation Test')
  };
};

//it is used for pages/device/UsedDeviceWarningModal.js
export const usedDeviceWarningMessages = () => {
  return {
    MODAL_HEADER: t('Devices Busy'),
    MODAL_MESSAGE: t(
      'The devices above are currently busy. Only privileged users can use them. Are you sure you want to connect?'
    )
  };
};

//it is used for pages/documentation/ArticleViewer.js
export const articleViewerMessages = () => {
  return {
    ERROR_MESSAGE: t('Failed to load article. Please try again.')
  };
};

//it is used for pages/documentation/DocumentationPage.js
export const documentationMessages = () => {
  return {
    NO_ARTICLE_HEADER: t('No Article'),
    NO_ARTICLE_MESSAGE: t('Select an article to display its content here.'),
    ERROR_MESSAGE: t('Failed to load data. Please try again.')
  };
};

//it is used for pages/manage/AddDeviceModal.js
export const addDeviceMessages = () => {
  return {
    DEVICE_LIST_ERROR_MESSAGE: t('Failed to get device list '),
    NOT_ADD_ANYMORE_DEVICE_MESSAGE: t('You cannot add any more devices'),
    ADD_DEVICE_HEADER: t('Add Device')
  };
};

//it is used for pages/manage/DeviceInstance.js
export const deviceInstanceMessages = (payload) => {
  return {
    WAIT_SWIPE_COMPLETE_MESSAGE: t('Please wait current swipe operation to complete.'),
    CONNECTION_BROKEN_MESSSAGE: t('The connection is broken'),
    CONNECTION_LOST_MESSAGE: t('The device connection has been lost.'),
    MAXIMUM_LIMIT_REACHED_MESSAGE: t(
      `Maximum ${payload} has been reached for your groups, please contact administrator to increase limit`
    ),
    LIMIT_ACCESS_ERROR_MESSAGE: t('LIMIT ACCESS ERROR'),
    SESSION_ERROR: t('SESSION ERROR'),
    FORBIDDEN_ERROR: t('FORBIDDEN'),
    OFFLINE_RESERVED :t('OFFLINE RESERVED'),
    BUSY_DEVICE :t('BUSY DEVICE'),
    RESERVATION_REMAINING_TIME_HEADER: t('Reservation Remaining Time'),
    BUSY_DEVICE_GROUP_ONLY : t('Device can be used by members belonging to the same group!'),
    BUSY_DEVICE_UNAVAILABLE : t('You are not authorized to use this busy device!'),
    BUSY_DEVICE_INFO_MSG : t('This device is being used by someone else, please wait for confirmation!'),
    RETURN_TO_DEVICE_LIST : t('Return to device list')
  };
};

//it is used for pages/manage/EmbeddedScreen.js
export const embeddedScreenMessages = () => {
  return {
    MAXIMUM_CONCURRENT_ACCESS_LIMIT_MESSAGE: t(
      'Maximum concurrent access limit has been reached for your groups, please contact administrator to purchase a new license'
    ),
    INVALID_ACCESS_TOKEN_MESSAGE: t('Invalid Access Token')
  };
};
//it is used for pages/manage/ManageAutomationSettings.js
export const manageAutomationSettingsMessages = () => {
  return {
    DESIRED_CAPABILITIES_LABEL: t('Desired Capabilities'),
    VISIUM_GO_LABEL: 'myenviroment.json',
    APP_NAME_LABEL: '<APPNAME>',
    APP_PACKAGE_LABEL: '<appPackage>',
    APP_ACTIVITY_LABEL: '<appActivity>',
    AUTOMATION_NAME_LABEL_ANDROID: 'uiautomator2',
    AUTOMATION_NAME_LABEL_IOS: 'xcuitest',
    AUTOMATION_KEY_LABEL: ':YOUR-AUTOMATION-KEY',
    REMOTE_HOST_LABEL: t('Remote Host'),
    REMOTE_PORT_LABEL: t('Remote Port'),
    REMOTE_PATH_LABEL: t('Remote Path'),
    DROPDOWN_PLACEHOLDER: t('Select Applications'),
    AUTOMATION_TYPES_EXCEPTION: t('Automation types could not fetched at the moment'),
    CODE_SNIPPET_DOES_NOT_EXIST: t('Code snippet do not exist !')
  };
};

//it is used for pages/manage/ManageDeviceInfo.js
export const manageDeviceInfoMessages = () => {
  return {
    SESSION_TYPE_HEADER: t('Session Type'),
    START_TIME_HEADER: t('Start Time')
  };
};

//it is used for pages/manage/ManageInspector.js
export const manageInspectorMessages = (id) => {
  return {
    NOT_START_INSPECTOR_MESSAGE: t('Unable to start inspector. Device is under Automation test.'),
    DEVICE_SPECS_NOT_FETCH: t('Error when fetching device specs '),
    NEW_SESSION_START_INFO: t('New session will be started.'),
    NEW_SESSION_STARTED_MESSAGE: t('New session started.'),
    SESSION_NOT_STARTED_MESSAGE: t('Session could not started.'),
    NOT_START_NEW_APPIUM_SESSION_ERROR_MESSAGE: t('Unable to start a new Appium session. Error '),
    NOT_START_APPIUM_WITH_THIS_DEVICE_PROPERTY_ERROR_MESSAGE: t(
      "Unable to start an Appium session with this device's properties."
    ),
    NOT_GETTING_DEVICE_ORIENTATION_VIA_APPIUM_ERROR_MESSAGE: t('Error while getting device orientation via Appium'),
    APPIUM_DRIVER_NOT_INITIALIZED_ERROR_MESSAGE: t('Appium driver could not be initialized.'),
    SOURCE_FETCH_SUCCESSFUL: t('Source is successfully fetched.'),
    APP_SOURCE_NOT_FETCH_BECAUSE_ERROR_MESSAGE: t('App source could not be fetched because '),
    APP_SOURCE_NOT_FETCH: t('App source could not be fetched.'),
    APP_SOURCE_FETCHED_PLEASE_WAIT_MESSAGE: t('App Source is being fetched. Please wait...'),
    KEY_SENT_FAILED_MESSAGE: t('Keys sent failed.'),
    ENTER_TEXT_PLACEHOLDER: t('Enter your text'),
    SEARCH_FOR_ELEMENT_HEADER: t('Search for Element'),
    LOCATOR_STRATEGY_LABEL: t('Locator Strategy'),
    LOCATOR_PLACEHOLDER: t('Locator'),
    ELEMENTS_LABEL: t('Elements'),
    COPY_ID_BUTTON: t('Copy ID'),
    TAP_ELEMENT_BUTTON: t('Tap Element'),
    ENTER_KEYS_PLACEHOLDER: t('Enter keys'),
    QUIT_INSPECTOR_HEADER: t('Quit Inspector'),
    QUIT_INSPECTOR_CONTENT_MESSAGE: t('Are you sure you want to quit Inspector?'),
    SOURCE_POPUP: t('Source'),
    SELECTED_ELEMENT_HEADER: t('Selected Element'),
    SELECT_ELEMENT_TO_BEGIN_MESSAGE: t('Select an element in the source to begin.'),
    REFRESH_SOURCE_POPUP: t('Refresh Source'),
    PAUSE_RECORDING_LABEL: t('Pause Recording'),
    START_RECORDING_LABEL: t('Start Recording'),
    CLICK_ELEMENT_WITH_ID_FAILED_MESSAGE: t('Clicking to element with id %s failed.').replace('%s', id),
    CLEAR_TEXT__FOR_ELEMENT_WITH_ID_FAILED_MESSAGE: t('Clear text for element with id %s failed.').replace('%s', id),
    START_INSPECTOR_BUTTON: t('Start Inspector'),
    BROWSER_MAY_NOT_BE_OPEN_MESSAGE: t('Browser may not be open at the moment'),
    NATIVE_CONTEXT_NOT_SWITCHED_MESSAGE: t('Native context could not switch !'),
    WEB_CONTEXT_NOT_SWITCHED_MESSAGE: t('Web context could not switch !'),
    NATIVE_IS_UP_MESSAGE: t('Native mode is already up and running'),
    WEB_HYBRID_UP_RUNNING_MESSAGE: t('Web/Hybrid mode is already up and running'),
    DOWNLOAD_SOURCE_TREE: t('Download Source Tree'),
    COPY_SOURCE_TREE: t('Copy Source Tree'),
    SWITCH_NATIVE_MODE: t('Switch Native Mode'),
    SWITCH_WEB_MODE: t('Switch Web Mode'),
  SELECT_INSPECTOR_MODE: t('Select an Inspector mode')
  };
};

//it is used for pages/manage/ManageLocation.js
export const manageLocationMessages = () => {
  return {
    NOT_SET_LOCATION_ERROR_MESSAGE: t('Failed to set location'),
    NOT_ADD_NEW_LOCATION_ERROR_MESSAGE: t('Failed to add new location'),
    NOT_GET_LOCATION_ERROR_MESSAGE: t('Failed to get location'),
    SAVED_LOCATIONS_LABEL: t('Saved Locations'),
    SAVE_AS_BUTTON: t('Save as'),
    GO_BUTTON: t('Go'),
    LCOATION_ERROR_MESSAGE: t('Location Error')
  };
};
//it is used for pages/manage/ManageLogs.js
export const manageLogMessages = (deviceOrAppium) => {
  return {
    PAUSE_LABEL: t('Pause'),
    FOLLOW_LABEL: t('Follow'),
    STOP_LOGS_LABEL: t(`Stop ${deviceOrAppium} Logs`),
    START_LOGS_LABEL: t(`Start ${deviceOrAppium} Logs`),
    DOWNLOAD_LOGS_LABEL: t(`Download ${deviceOrAppium} Logs`),
    APPIUM_LOGS_HEADER: t('Appium Logs'),
    LOCATION_HEADER: t('Location')
  };
};
//it is used for pages/manage/MultiManageOperations.js
export const multiManageOperationMessages = (packageName, openOrClose, rebootOrShutdown) => {
  return {
    REBOOT_SUCCESSFUL_MESSAGE: t('Reboot Success'),
    REBOOT_FAILED_MESSAGE: t('Reboot Failed Error Code:'),
    SHUTDOWN_SUCCESSFUL_MESSAGE: t('Shutdown Success'),
    SHUTDOWN_FAILED_MESSAGE: t('Shutdown Failed Error Code:'),
    GPS_DISABLED_MESSAGE: t('GPS Disabled'),
    GPS_ENABLED_MESSAGE: t('Gps Enabled'),
    WIFI_DISABLED_MESSAGE: t('WIFI Disabled'),
    WIFI_ENABLED_MESSAGE: t('WIFI Enabled'),
    SCREENSHOT_TAKEN_MESSAGE: t('Screenshot Taken.'),
    SCREENSHOT_NOT_TAKEN_ERROR_MESSAGE: t('Screenshot could not take. Status Code '),
    SCREENSHOT_HEADER_PORTAL: t('Screenshot'),
    SCREENSHOT_CONTENT_PORTAL: t('Taking screenshot this device...'),
    VIDEO_RECORD_STOPPED_MESSAGE: t('Video Record Stopped.'),
    VIDEO_RECORD_NOT_STOPPED_ERROR_MESSAGE: t('Video record could not stop. Status Code '),
    VIDEO_RECORD_STARTED_MESSAGE: t('Video Record Started.'),
    VIDEO_RECORD_NOT_STARTED_ERROR_MESSAGE: t('Video record could not start. Status Code '),
    SCREEN_RECORDING_HEADER_PORTAL: t('Screen Recording'),
    SCREEN_RECORDING_CONTENT_PORTAL: t('Recording Now..'),
    SCREEN_RECORDING_NOT_SUCCESS_CONTENT_PORTAL: t(`Server response is not 'SUCCESS'`),
    STOP_AND_DOWNLOAD_BUTTON: t('Stop And Download'),
    STOP_AND_PLAY_BUTTON: t('Stop And Play'),
    PACKAGE_NOT_FOUND_APP_LIBRARY: t('%s package is not found in the app library').replace('%s', packageName),
    APP_SUCCESSFULLY_INSTALLED_TO_DEVICE_MESSAGE: t(
      'The application has been successfully installed on the this device.'
    ),
    DEPLOYMENT_FAILED_ERROR_MESSAGE: t('Deployment Failed '),
    FILE_HAS_BEEN_SUCESSFULLY_DEPLOYED: t('File has been sucessfully deployed'),
    COULD_NOT_GET_THE_FILE_LIST: t('Could not get the file list'),
    DEPLOYMENT_FAILED_UNKNOWN_ERROR_MESSAGE: t('Deployment Failed Unknown Error '),
    DEVICE_LOG_RECORD_STOPPED_MESSAGE: t('Device Log Record Stopped.'),
    DEVICE_LOG_RECORD_NOT_STOPPED_ERROR_MESSAGE: t('Device log record could not stop. Status Code '),
    DEVICE_LOG_RECORD_STARTED_MESSAGE: t('Device Log Record Started.'),
    DEVICE_LOG_RECORD_NOT_STARTED_ERROR_MESSAGE: t('Device log record could not start. Status Code '),
    NETWORK_TRAFFIC_RECORD_STOPPED_MESSAGE: t('Network Traffic Record Stopped.'),
    NETWORK_TRAFFIC_RECORD_STARTED_MESSAGE: t('Network Traffic Record Started.'),
    NETWORK_TRAFFIC_RECORD_NOT_STOPPED_ERROR_MESSAGE: t('Network traffic record could not stop. Status Code:'),
    NETWORK_TRAFFIC_RECORD_NOT_STARTED_ERROR_MESSAGE: t('Network traffic record not start. Status Code '),
    REFRESH_TOKEN_ERROR_MESSAGE: t('Refresh Embedded Screen Token Failed Error Code '),
    OPEN_OR_CLOSE_DETAILED_DEVICE_INFO: t(`${openOrClose} Detailed Device Info`),
    REMOVE_DEVICE_POPUP: t('Remove Device'),
    SCREEN_RECORD_POPUP: t('Screen Record'),
    TAKE_SCREENSHOT_POPUP: t('Take Screenshot'),
    SAVE_DEVICE_LOGS_POPUP: t('Save Device Logs'),
    SAVE_TRAFFIC_NETWORK_POPUP: t('Save Network Traffic'),
    TURN_SCREEN_POPUP: t('Turn Screen'),
    VIDEO_QUALITY_LABEL: t('Video Quality'),
    BATTERY_CPU_MEMORY_USAGE_LABEL: t('Battery, CPU, Memory Usage by App'),
    MULTI_TOUCH_POPUP: t('Multi Touch'),
    TURN_SMART_SCREENSHOT_POPUP: t('Turn %s Smart Screenshot').replace('%s', openOrClose ? t('Off') : t('On')),
    APPLICATION_DEPLOYMENT_POPUP: t('Application Deployment'),
    APPS_IN_THIS_DEVICE_POPUP: t('Applications In This Device'),
    SHARE_SCREEN_POPUP: t('Share Screen'),
    REBOOT_POPUP: t('Reboot'),
    SHUTDOWN_POPUP: t('Shutdown'),
    LOCK_SCREEN_POPUP: t('Lock Screen'),
    CHANGE_DEVICE_LOCALE_POPUP: t('Change Device Locale'),
    CHANGE_DEVICE_LOCALE_PLACEHOLDER: t('Enter locale. E.g. en_US'),
    ALL_APPLICATIONS_HEADER: t('All Applications'),
    VERSION_NAME_LABEL: t('Version Name'),
    SELECT_APP_FROM_LIST_LABEL: t('Select an application from list'),
    REBOOT_SHUTDOWN_CONFIRM_HEADER: t(rebootOrShutdown),
    REBOOT_SHUTDOWN_CONFIRM_MESSAGE: t(`Are you really sure to ${rebootOrShutdown} this device?`),
    VERSION_CODE: t(`Version Code`),
    VERSION_NAME: t(`Version Name`),
    CATEGORY: t(`Category`),
    DEPLOY: t(`Deploy`),
    SELECT_A_FILE_FROM_LIST: t(`Select a file from list`),
    YOU_DO_NOT_HAVE_PERMISSION_TO_DEPLOY_APPLICATION_TO_DEVICE: t(
      `You do not have permission to deploy application to device`
    ),
    YOU_DO_NOT_HAVE_PERMISSION_TO_SEE_DEPLOY_FILE_TO_DEVICE: t(`You do not have permission to deploy file to device`),
    ALL_FILES: t('All Files'),
    FILES: t(`Files`),
    THERE_ARE_NO_FILES_ON_LIBRARY: t(`There are no files on library`),
    UPLOADER: t(`Uploader`),
    FILE_NAME: t(`File Name`),
    NAME: t(`Name`),
    NO_FILE_MATCH: t(`No file Match`),
    SEARCH_A_FILE: t(`'Search a file...`),
    REBOOT_PHONE: t('Reboot Phone'),
    NO_APPLICATION_FOUND_FOR_SEARCH: t('No application found for the search criteria'),
    HARDWARE_SIMULATE: t('Hardware Simulation')
  };
};
//it is used for pages/manage/MultiManagePage.js
export const multiManageMessages = () => {
  return {
    DEVICE_TIMEOUT_REMAINING_TIME_NOTIFY_MESSAGE: t('Device Timeout Remaining Time'),
    NOT_STOPPED_TEST_SESSION: t("Can't Stop Test Session"),
    DEVICE_ID_REQUIRED_ERROR_MESSAGE: t('Device ID is required'),
    BACK_TO_DEVICE_LIST_POPUP: t('Back to Device List'),
    END_SESSION: t('End Session')
  };
};

//src/components/AppSourceTree.js
export const appSourceTreeMessages = () => {
  return{
    CONVERTING_XML_TO_JSON_FAILED_MESSAGE: t('Converting XML to JSON is Failed. Error')
  }
}

//src/components/ChangeActiveGroupModal.js
export const changeActiveGroupMessages = () => {
  return {
    INFO_LABEL: t('You can later change the active group from the tab under the profile section.')
  }
}

//it is used for pages/options/agent/AgentConfig.js
export const agentConfigMessages = () => {
  return {
    TRAFFIC_OPTION: t('Traffic'),
    RESIGN_SERVER_OPTION: t('Resign Server'),
    AGENT_TYPE_LABEL: t('Agent Type'),
    NOT_FETCH_AGENT_LIST: t("Can't fetch agents list. Error:"),
    AGENT_CONFIGURATION_HEADER: t('Agent Configurations'),
    AGENT_CONFIGURATION_SUB_HEADER: t('Add, Edit or Delete agents configurations'),
    ADD_NEW_AGENT_BUTTON: t('Add New Agent'),
    AGENT_FIELDS_LABEL: t('Agent Fields'),
    DELETE_CONFIRM: t('Are you sure you want to delete this agent config?')
  };
};

//it is used for pages/options/AppRestrictionsPage.js
export const appRestrictionsMessages = () => {
  return {
    WHITELIST_HEADER: t('Whitelist'),
    ENABLED_HEADER: t('Enabled'),
    NOT_FECTH_APP_RESTRICTIONS: t('Could not fetch restrictions. Error:'),
    APP_RESTRICTIONS_SUB_HEADER: t('Manage all application restrictions'),
    ADD_NEW_APP_WHITELIST_BUTTON: t('Add New App Whitelist'),
    APP_RESTRICTION_MODAL_HEADER: t('Application Whitelist Fields'),
    DELETE_CONFIRM: t('Are you sure you want to delete this restriction?')
  };
};

//it is used for pages/options/CertificatesPage.js, CertificateDetailsModal.js
export const certificatesMessages = () => {
  return {
    CERTIFICATE_NAME_HEADER: t('Certificate Name'),
    NOT_FETCH_CERTIFICATES: t('Could not be fetched certificates. Error'),
    WRONG_CERTIFICATE_PASSWORD_MESSAGE: t('Wrong certificate password!'),
    CERTIFICATES_SUB_HEADER: t('Manage All Certificates on the System'),
    ADD_NEW_CERTIFICATE_BUTTON: t('Add New Certificate'),
    CERTIFICATES_MODAL_HEADER: t('Certificate Fields'),
    CERTIFICATE_FILE_HEADER: t('Certificate File'),
    CERTIFICATE_PASSWORD_LABEL: t('Certificate Password'),
    DELETE_CONFIRM: t('Are you sure you want to delete this certificate?'),
    CERTIFICATE_DETAILS_HEADER: t('Certificate Details')
  };
};

//it is used for pages/options/EmailConfig.js
export const emailConfigMessages = () => {
  return {
    NOT_FETCH_MAIL_SERVER_LIST: t("Can't fetch email servers list. Error:"),
    MAIL_SERVER_SUB_HEADER: t('Add, Edit or Delete Mail servers for notification email'),
    ADD_NEW_SERVER_BUTTON: t('Add New Server'),
    EMAIL_SERVER_CONFIGURATION_HEADER: t('Email Server Configuration'),
    SERVER_FIELDS_LABEL: t('Server Fields'),
    USE_STARTTLS_LABEL: t('Use STARTTLS'),
    TEST_MAIL_SETTINGS_HEADER: t('Test Mail Settings'),
    TEST_MAIL_SETTINGS_MODAL_CONTENT: t('Use your mail and message to test your Mail server settings.'),
    MAIL_LABEL: t('Mail'),
    MESSAGES_LABEL: t('Message'),
    DELETE_CONFIM: t('Are you sure you want to delete this mail server config?')
  };
};

//it is used for pages/options/slave/SlaveConfigPage.js
export const secondaryServerConfigMessages = () => {
  return {
    SECONDARY_SERVER_CONFIGURATION_HEADER: t('Node Configuration'),
    SECONDARY_SERVER_CONFIGURATION_SUB_HEADER: t('Edit node configurations'),
    SECONDARY_SERVER_NAME : t('Node Name'),
    SECONDARY_SERVER_UPDATE_ERR : t('Error occured while updating the node!'),
    SECONDARY_SERVER_IP: t('Node IP'),
    SECONDARY_SERVER_PORT : t('Node Port'),
    SECONDARY_SERVER_FIELDS : t('Node Fields'),
    SECONDARY_SERVER_LOCATION : t('Node Location'),
    DOWNLOAD_DIAGNOSIS : t('Download Diagnosis'),
    DELETE_CONFIRM: t('Are you sure you want to delete this node?')
  };
};

//it is used for pages/options/Integrations.js,IntegrationEdit.js
export const integrationsMessages = () => {
  return {
    VISIUM_MANAGE_MENU_ITEM: 'Visium Manage',
    INTEGRATIONS_SUB_HEADER: t('Manage All Integrations'),
    NOT_FETCH_INTEGRATION_DETAILS: t('Cannot fetch Visium Manage integration details. Error:'),
    UPDATE_ERROR_MESSAGE: t('Cannot update Visium Manage integration. Error:'),
    WRONG_URL_FORMAT_MESSAGE: t('Wrong integration URL format!'),
    ADD_NEW_PARAMETER_POPUP: t('Add New Parameter'),
    VALUE_LABEL: t('Value'),
    PARAMETER_DELETE_MESSAGE: t('Parameter has been deleted'),
    DATA_NOT_AVAILABLE_MESSAGE: t('Data Not Available'),
    DEFAULT_PARAMETERS_LABEL: t('Default Parameters'),
    EMPTY_OR_DUPLICATE_KEY: t('The parameter key is either duplicated or empty!'),
    EDIT_INTEGRATION_VALUES: t('Edit Integration Values')
  };
};

//it is used for pages/options/LdapConfigPage.js
export const ldapConfigMessages = () => {
  return {
    SERVER_NAME_HEADER: t('Server Name'),
    SERVER_HEADER: t('Server'),
    GROUP_SEARCH_FILTER_HEADER: t('Group Search Filter'),
    ROOT_DN_HEADER: t('Root DN'),
    USER_SEARCH_FILTER_HEADER: t('User Search Filter'),
    NOT_FETCH_LDAP_LIST: t("Can't fetch LDAP servers list. Error"),
    LDAP_SERVERS_SUB_HEADER: t('Add, Edit or Delete Ldap servers'),
    ADD_NEW_SERVER_BUTTON: t('Add New Server'),
    LDAP_FIELDS_LABEL: t('Ldap Fields'),
    MANAGER_DN_LABEL: t('Manager DN'),
    MANAGER_PASSWORD_LABEL: t('Manager Password'),
    AUTO_REGISTER_USER_GROUPS_LABEL: t('Auto Register User Groups'),
    EXTRA_LDAP_FIELDS_LABEL: t('Extra LDAP Fields'),
    DELETE_CONFIRM: t('Are you sure you want to delete this ldap server config?'),
    LDAP_CONNECTION_TEST_BUTTON: t('LDAP Connection Test'),
    USER_AUTHENTICATION_TEST_BUTTON: t('User Authentication Test'),
    TEST_LDAP_SETTINGS_HEADER: t('Test LDAP Settings'),
    TEST_LDAP_SETTINGS_SUB_HEADER: t('Use your LDAP username and password to test your LDAP settings'),
    CHECK_AUTHENTICATION_PARAMETER_OR_NETWORK_PARAMETER: t(
      'If manager DN and manager password are present it checks the authentication parameter, otherwise it checks the network parameter(URL).'
    )
  };
};

//it is used for pages/options/Locations.js
export const locationsMessages = (createOrUpdate) => {
  return {
    NOT_CREATE_OR_UPDATE_LOCATION_ERROR_MESSAGE: t(`Failed to ${createOrUpdate} location: `),
    NOT_DELETE_LOCATION_ERROR_MESSAGE: t('Failed to delete location: '),
    NOT_FETCH_LOCATION_LIST: t('Failed to get location list:'),
    SYSTEM_LOCATIONS_HEADER: t('System Locations'),
    SYSTEM_LOCATIONS_SUB_HEADER: t('Create system locations'),
    MY_LOCATIONS_HEADER: t('My Locations'),
    CREATE_USER_LOCATION_SUB_HEADER: t('Create user locations'),
    ADD_NEW_LOCATION_BUTTON: t('Add New Location'),
    LOCATION_PROPERTIES_HEADER: t('Location Properties'),
    DELETE_CONFIRM: t('Are you sure you want to delete this location?')
  };
};
//it is used for pages/options/OtherOpsPage.js
export const otherOpsMessages = (rebootOrShutdown) => {
  return {
    NOT_CREATE_OR_FETCH_LICENSE_INFO_ERROR_MESSAGE: t('Could not create or fetch license information. Error: '),
    NOT_FETCH_NODES: t('Could not fetch nodes. Error'),
    ALL_LOGS_LABEL: t(' All Logs'),
    ALL_DATA_LABEL: t('All data'),
    LOGS_LABEL: t(' Logs'),
    UI_LOGS_LABEL: t('UI Logs'),
    GRID_HUB_DIAGNOSTICS_LABEL: t('Grid Hub Diagnostics'),
    NOT_FETCH_LOGS: t('Could not fetch logs. Error'),
    NOT_FETCH_DEVICE_UUID_ERROR_MESSAGE: t("Can't fetch device uuid. Error: "),
    OTHER_SETTINGS_HEADER: t('Other Settings'),
    OTHER_SETTINGS_SUB_HEADER: t('Language and license information'),
    LICENSE_INFORMATION_HEADER: t('License Information'),
    DEVICE_UUID_CELL: t('Device UUID'),
    LICENSE_DETAIL_CELL: t('License Detail'),
    LICENSE_SIGNATURE_LABEL: t('License Signature'),
    NEW_LICENSE_HEADER: t('New License'),
    PLEASE_WAIT_LOADER: t('Please Wait...'),
    RESTART_DEVICE_STATUS_MODAL_HEADER: t('Restart Device Status'),
    SHUTDOWN_OR_RESTART_ALL_DEVICES_CONFIRM_HEADER: t(rebootOrShutdown),
    SHUTDOWN_OR_RESTART_ALL_DEVICES_CONFIRM_MESSAGE: t('%s all devices, are you sure you want to do this?').replace(
      '%s',
      t(rebootOrShutdown)
    ),
    COLLECT_SYSTEM_DATA_DROPDOWN_TEXT: t('Collect System Data'),
    RESTART_ALL_DEVICES_BUTTON: t('Restart All Devices'),
    SHUTDOWN_ALL_DEVICES_BUTTON: t('Shutdown All Devices')
  };
};

//it is used for pages/options/RolePage.js
export const roleMessages = () => {
  return {
    NOT_FETCH_PRIVILEGE_GROUP_ERROR_MESSAGE: t("Can't fetch privilege groups from server: "),
    NOT_FETCH_PRIVILEGES_ERROR_MESSAGE: t("Can't fetch privileges from server: "),
    ROLES_SUB_HEADER: t('Create role and privilege for users'),
    ADD_NEW_ROLE_BUTTON: t('Add New Role'),
    ROLE_PROPERTIES_MODAL_HEADER: t('Role Properties'),
    ROLE_NAME_LABEL: t('Role Name'),
    ROLE_DESCRIPTION_LABEL: t('Description'),
    PRIVILEGES_HEADER: t('Privileges'),
    DELETE_CONFIRM: t('Are you sure you want to delete this role?'),
    NO_ENTRY_MSG: t('No Entry')
  };
};
//it is used for pages/options/SystemParametersPage.js
export const systemParametersMessages = () => {
  return {
    CURRENT_VALUE_HEADER: t('Current Value'),
    GIF_CAPTCHA_OPTION: 'Gif Captcha',
    ARITHMETIC_CAPTCHA_OPTION: t('Arithmetic Captcha'),
    ALPHANUMERIC_CAPTCHA_OPTION: t('AlphaNumeric Captcha'),
    SYSTEM_PARAMETERS_SUB_HEADER: t('Manage All System Parameters'),
    PARAMETER_PROPERTIES_HEADER: t('Parameter Properties'),
    PARAMETER_NAME_LABEL: t('Parameter Name'),
    DELETE_CONFIRM: t('Are you sure you want to delete this system parameter?'),
    DO_NOTHING: t('Do nothing'),
    DISABLE_USER: t('Disable User'),
    DELETE_USER: t('Delete User')
  };
};

//it is used for pages/options/usergroup/GroupUsersReservationModal.js
export const groupUsersReservationMessages = () => {
  return {
    NOT_FETCH_RESERVATION_USERS_ERROR_MESSAGE: t('Can not fetch reservation users. Error')
  };
};

//it is used for pages/options/usergroup/ManageGroupApplications.js
export const manageGroupApplicationsMessages = () => {
  return {
    NOT_FETCH_GROUP_APPLICATIONS_ERROR_MESSAGE: t('Can not fetch group applications. Error:')
  };
};
//it is used for pages/options/usergroup/ManageGroupDevices.js
export const manageGroupDevicesMessages = () => {
  return {
    NOT_FETCH_GROUP_DEVICES_ERROR_MESSAGE: t('Can not fetch group devices. Error:'),
    NOT_FETCH_DEVICES_ERROR_MESSAGE: t('Can not fetch devices. Error'),
    SEARC_DEVICE_PLACEHOLDER: t('Search Device')
  };
};

//it is used for pages/options/usergroup/ManageGroupUsers.js
export const manageGroupUsersMessages = () => {
  return {
    NOT_FETCH_GROUP_USERS_ERROR_MESSAGE: t('Can not fetch group users. Error'),
    NOT_FETCH_USERS_ERROR_MESSAGE: t('Can not fetch users. Error: '),
    TOTAL_NUMBER_OF_USERS_LABEL: t('Total Number of Users')
  };
};

//it is used for pages/options/usergroup/ManageGroupFiles.js
export const manageGroupFilesMessages = () => {
  return {
    NOT_FETCH_GROUP_FILES: t('Can not fetch group files. Error: '),
    NOT_FETCH_FILES: t('Can not fetch files. Error: '),
    SEARCH_FILE_PLACEHOLDER: t('Search File')
  };
};

//it is used for pages/options/usergroup/NewGroupModal.js
export const newGroupMessages = errorMessage => {
  return {
    EDIT_RESERVATION_USER_LIST_BUTTON: t('Edit Reservation User List'),
    NO_LIMIT_LABEL: t('No Limit'),
    Not_ACTIVE_AFTER: t('Not Active After'),
    CREATE_UPDATE_GROUP_ERROR_MESSAGE: t(errorMessage),
    PERMANENT_GROUP: t('Permanent Group'),
    TEMPORARY_GROUP: t('Temporary Group'),
  };
};

//it is used for pages/options/usergroup/UserGroupsPage.js
export const userGroupsMessages = () => {
  return {
    NOT_FETCH_GROUPS: t("Can't fetch groups from server."),
    USER_GROUPS_SUB_HEADER: t('Manage all user groups'),
    ADD_NEW_GROUP_BUTTON: t('Add New User Group'),
    DELETE_CONFIM: t('Are you sure you want to delete this user group?')
  };
};

//it is used for pages/options/usermanagement/AddldapUsers.js
export const addLdapUsersMessages = () => {
  return {
    SEARCH_TYPE_DROPDOWN_EMAIL_OPTION: t('Email'),
    SEARCH_TYPE_DROPDOWN_DEPARTMENT_OPTION: t('Department'),
    LDAP_DROPDOWN_ALL_SERVERS_OPTION: t('All Servers'),
    SEARCH_LDAP_USER_MODAL_HEADER: t('Search LDAP user'),
    SELECT_LDAP_SERVER_LABEL: t('Select LDAP Server'),
    SELECTED_USERS_LABEL: t('Selected Users'),
    ADD_SELECTED_USERS_BUTTON: t('Add Selected Users')
  };
};

//it is used for pages/options/usermanagement/AddNewUser.js
export const addNewUserMessages = () => {
  return {
    MODAL_HEADER: t('Add or Edit User'),
    FULLNAME_INPUT_INFO_MESSAGE: t(
      'Each word in the full name must be a minimum of 2 and a maximum of 30 characters in total and it can also contain period character.'
    ),
    USERNAME_INPUT_INFO_MESSAGE: t(
      "Username must contain a minimum of 3 and a maximum of 15 characters and can only contain alphanumeric, '.-_' characters."
    ),
    PASSWORD_INPUT_INFO_MESSAGE: t('If you do not want to change the password, do not fill this field.'),
    NOTIFICATION_USER_POPUP_MESSAGE: t('When device status (Online/Offline) is changed, send email to this user.'),
    NOTIFICATION_USER_LABEL: t('Notification User')
  };
};
//it is used for pages/options/usermanagement/ManageUsers.js
export const manageUsersMessages = () => {
  return {
    LDAP_USER_HEADER: t('Ldap User'),
    TWO_FACTOR_AUTH_HEADER: t('Two Factor Authentication'),
    RESET_TWO_FACTOR_AUTH_SECRET_KEY: t('Click To Reset Two Factor Authentication Secret Key'),
    NO_GROUP_LABEL: t('No Group'),
    USER_ACTIVITIES_HEADER: t('User Activities'),
    LAST_LOGON_LABEL: t('Last Logon'),
    NEVER_LOGGED_IN_LABEL: t('Never Logged In'),
    DATE_OF_REGISTRATION_LABEL: t('Date Of Registration'),
    NOT_FETCH_USER_GROUPS_ERROR_MESSAGE: t("Can't fetch user groups from server."),
    USER_DELETE_SUCCESSFULL: t('User successfully deleted'),
    USER_CREATED_SUCCESSFULL: t('User Created Successfully'),
    USERNAME_ALREADY_TAKEN_INFO_MESSAGE: t('This username already taken.'),
    MAIL_ALREADY_TAKEN_INFO_MESSAGE: t('This mail address already taken.'),
    USER_EDITED_SUCCESSFULL: t('User Edited Successfully'),
    NOT_DELETE_TWO_FACTOR_AUTH_SECRET_KEY_ERROR_MESSAGE: t('Two Factor Authentication Secret Key could not be deleted'),
    USER_MANAGEMENT_HEADER: t('User Management'),
    USER_MANAGEMENT_SUB_HEADER: t('Manage all users'),
    PASSWORD_RULES_BUTTON: t('Password Rules'),
    ADD_LDAP_SERVER_BUTTON: t('Add LDAP User'),
    ADD_NEW_USER_BUTTON: t('Add New User'),
    RESET_TWO_FACTOR_CONFIRM_MESSAGE: t(
      "Are you sure you want to reset this user's Two-factor authentication secret key?"
    ),
    DELETE_USER_CONFIRM_MESSAGE: t('Are you sure you want to delete this user?')
  };
};

//it is used for pages/options/usermanagement/PasswordRules.js
export const passwordRulesMessages = () => {
  return {
    NOT_FETCH_PASSWORD_RULES: t("Can't fetch password rules. Error:"),
    MODAL_HEADER: t('Password Rules for Local Users'),
    LETTER_RULES_LABEL: t('Letter Rules'),
    LOWERCASE_LETTERS_LABEL: t('Lowercase Letters'),
    UPPERCASE_LETTERS_LABEL: t('Uppercase Letters'),
    BOTH_UPPERCASE_LOWERCASE_LETTERS_LABEL: t('Both Uppercase and Lowercase Letters'),
    CHARACTER_RULES_LABEL: t('Character Rules'),
    NUMBERS_LABEL: t('Numbers'),
    SPECIAL_CHARACTERS_LABEL: t('Special Characters'),
    CHARACTER_LIMIT_LABEL: t('Character Limit')
  };
};

//it is used for pages/options/webhook/Webhook.js,WebhookModal.js
export const webhookMessages = (webhookId) => {
  return {
    EVENT_TYPE_HEADER: t('Event Type'),
    NOT_FETCH_WEBHOOK_LIST: t("Can't fetch webhook list. Error:"),
    WEBHOOK_DELETE_SUCCESSFUL: t('Webhook %s has been deleted successfully').replace('%s', webhookId),
    WEBHOOK_MANAGEMENT_HEADER: t('Webhook Management'),
    WEBHOOK_MANAGEMENT_SUB_HEADER: t('Manage all webhooks'),
    ADD_NEW_WEBHOOK_BUTTON: t('Add New Webhook'),
    DELETE_CONFIRM_HEADER: t('Delete Webhook'),
    DELETE_CONFIRM_MESSAGE: t('Are you sure you want to delete this webhook?'),
    WEBHOOK_FIELDS_MODAL_HEADER: t('Webhook Fields'),
    AUTHENTICATION_METHOD_LABEL: t('Authentication Method'),
    TOKEN_HEADER_LABEL: t('Token Header'),
    TOKEN_VALUE_LABEL: t('Token Value'),
    WEBHOOK_CONNECTION_TEST_BUTTON: t('Webhook Connection Test')
  };
};

//it is used for pages/options/version/Versions.js
export const versionMessages = () => {
  return {
    VERSION_SUB_HEADER: t('Version Information Visium Farm'),
    FRONTEND_LABEL: 'Frontend',
    BACKEND_LABEL: 'Backend'
  };
};

//it is used for pages/session/AutomationTestSession.js
export const automationTestSessionMessages = () => {
  return {
    APPIUM_SESSION_HEADER: t('Appium Session'),
    NOT_FETCH_AUTOMATION_TEST_SESSION: t("Can't fetch Automation Test Sessions. Error "),
    SEARCH_REPORT_ID_PLACEHOLDER: t('Search Report ID'),
    SEARCH_SESSION_ID_PLACEHOLDER: t('Search Session ID'),
    DELETE_CONFIRM: t('Are you sure you want to delete this report?'),
  };
};
//it is used for pages/session/ManualTestSessionModal.js
export const manualTestSessionModalMessages = () => {
  return {
    MODAL_HEADER: t('New Session'),
    NO_AVAILABLE_DEVICE_MESSAGE: t('No Available Device'),
    NO_AVAILABLE_APP_MESSAGE: t('No Available Application'),
    RECORDS_LABEL: t('Records'),
    RECORD_VIDEO_MESSAGE: t('Record video during session'),
    VIDEO_RECORD_LABEL: t('Video Record'),
    RECORD_DEVICE_LOG_MESSAGE: t('Record device logs during session'),
    RECORD_NETWORK_TRAFFIC_MESSAGE: t('Record network traffic during session'),
    NETWORK_TRAFFIC_LABEL: t('Network Traffic'),
    OPTIONS_LABEL: t('Options'),
    UNINSTALL_APP_MESSAGE: t('Uninstall application after the session is over'),
    UNINSTALL_APP_LABEL: t('Uninstall Application'),
    START_SESSION_BUTTON: t('Start Session'),
    TOKENS_COULD_NOT_RESTORED: t('Tokens could not restored'),
    PROJECTS_COULD_NOT_FETCHED: t('Projects could not fetched')
  };
};
//it is used for pages/session/ManualTestSessions.js
export const manualTestSessionMessages = () => {
  return {
    NOT_FETCH_MANUAL_TEST_SESSION: t("Can't fetch Manual Test Sessions. Error:"),
    NOT_START_MANUAL_TEST_SESSION: t("Can't Start Manual Test Session"),
    NOT_DELETE_TEST_SESSION: t("Can't Delete Test Session"),
    START_NEW_SESSION_BUTTON: t('Start New Session'),
    DELETE_CONFIRM: t('Are you sure you want to delete this test session?'),
  };
};

//it is used for pages/session/TestSessionDetailsModal.js
export const testSessionDetailMessages = () => {
  return {
    TEST_SESSION_INFO_LABEL: t('Test Session Info'),
    TEST_SESSION_DETAIL_LABEL: t('Test Session Detail'),
    USER_DETAIL_LABEL: t('User Detail'),
    DEVICE_DETAIL_LABEL: t('Device Detail'),
    DEVICE_BRAND_LABEL: t('Device Brand'),
    DEVICE_OS_LABEL: t('Device OS'),
    DEVICE_OS_VERSION_LABEL: t('Device OS Version'),
    TEST_SESSION_FILES_LABEL: t('Test Session Files'),
    ZIP_FILE_LABEL: t('Zip File'),
    ALL_FILES_DOWNLOAD_BUTTON: t('All Files Download')
  };
};

//it is used for pages/session/TestSessionPage.js
export const testSessionMessages = () => {
  return {
    MANUAL_SESSION_BUTTON: t('Manual Sessions'),
    AUTOMATION_SESSION_BUTTON: t('Automation Sessions')
  };
};

//it is used for pages/usage/tabs/Devices/ChartsDEV.js, Devices.js
export const chartsDevMessages = () => {
  return {
    ALL_DEVICES_HEADER: t('All Devices'),
    ANDROID_VERSIONS_HEADER: t('Android Versions'),
    IOS_VERSIONS_HEADER: t('IOS Versions'),
    USED_DEVICES_HEADER: t('Used Devices')
  };
};
//it is used for pages/usage/tabs/DeviceUsage/ChartsDU.js, ChartsDUStats.js, ChartsHourlyUsage.js, DeviceUsage.js, TableColumnsDU.js
export const chartsDeviceUsageMessages = (usageStatus) => {
  return {
    USAGE_DURATIONS_BY_DATE: t('Usage Durations By Date'),
    USAGE_DURATIONS_BY_MONTH: t('Usage Durations By Month'),
    USED_DEVICES_COUNT_BY_DATE: t('Used Devices Count By Date'),
    USED_DEVICES_COUNT_BY_MONTH: t('Used Devices Count By Month'),
    TOP_DEVICES_BY_USAGE: t('Top Devices by Usage'),
    DEVICE_COUNT_LABEL: t('Device Count'),
    DEVICE_USAGE_DURATION_LABEL: t('Device Usage Duration'),
    DEVICE_USAGE_STATUS_HEADER: t(usageStatus),
    ANDROID_USAGE_STATS_LABEL: t('Android Usage Stats'),
    IOS_USAGE_STATS_LABEL: t('IOS Usage Stats'),
    TIME_INTERVALS_LABEL: t('Time Intervals'),
    HOURLY_USAGE_HEADER: t('Hourly Usage'),
    STATS_HEADER: t('Stats'),
    TOP_DEVICES_MENU_ITEM: t('Top Devices'),
    USAGE_COUNT_DAY_MENU_ITEM: t('Usage Count (Day)'),
    USAGE_COUNT_MONTH_MENU_ITEM: t('Usage Count (Month)'),
    USAGE_DURATIONS_DAY_MENU_ITEM: t('Usage Durations (Day)'),
    USAGE_DURATIONS_MONTH_MENU_ITEM: t('Usage Durations (Month)'),
    USAGE_MAP_MENU_ITEM: t('Usage Map')
  };
};
//it is used for pages/usage/tabs/NumberOfUsers/ChartsNOU.js, NumberOfUsers.js
export const chartsNumberOfUsersMessages = (numberOfUsersType) => {
  return {
    ACTIVE_USERS_LABEL: t('Active Users'),
    INACTIVE_USERS_LABEL: t('Inactive Users'),
    NUMBER_OF_USERS_BY_HEADER: t('Number of users by %s').replace('%s', t(numberOfUsersType)),
    TOTAL_USER_LABEL: t('Total User'),
    REGISTERED_USERS_HEADER: t('Registered Users')
  };
};
//it is used for pages/usage/tabs/RawUsageData/ChartsRUD.js, RawUsageData.js
export const chartsRawUsageDataMessages = () => {
  return {
    DEVICE_NAME_HEADER: t('Device Name'),
    START_USAGE_HEADER: t('Start Usage'),
    END_USAGE_HEADER: t('End Usage'),
    USAGE_TIME_HEADER: t('Usage Time'),
    LESS_THAN_A_SECOND_LABEL: t('Less than a second'),
    NOT_DOWNLOAD_REPORTS_ERROR_MESSAGE: t("Can't download reports. Error  "),
    NOT_FETCH_DEVICE_USAGE: t('Could not fetch device usages'),
    DOWNLOAD_OPERATION_STATUS_INFO: t('Exporting raw usage data may take a while. You will be notified when it is finished.'),
    EXPORT_RAW_USAGE_DATA_COMPLETED_INFO_MESSAGE: t('Export of raw usage data is completed.')
  };
};

//it is used for pages/usage/tabs/Reservations/ChartsRES.js, Reservations.js
export const chartsReservationsMessages = (durationType) => {
  return {
    ALL_RESERVATIONS_BY_HEADER: t('All Reservations by %s').replace('%s', t(durationType))
  };
};
//it is used for pages/usage/tabs/TopUsers/ChartsTU.js, TopUsers.js
export const chartsTopUsersMessages = (usageType) => {
  return {
    TOP_USERS_BY_HEADER: t('Top Users by %s').replace('%s', `${t(usageType)} ${USAGE_LABEL()}`),
    GENERAL_MENU_ITEM: t('General'),
    USAGE_TYPE_MENU_ITEM: t('Usage Type')
  };
};

//it is used for pages/usage/tabs/UserReservations/ChartUSERRES.js, TableColumnsUSERRES.js, UserReservations.js
export const chartsUserReservationsMessages = (durationType) => {
  return {
    RESERVATION_USAGE_BY_HEADER: t('Reservation Usages by %s').replace('%s', t(durationType))
  };
};

//it is used for pages/usage/Alarms.js
export const alarmsMessages = (summaryValue) => {
  return {
    SUMMARY_HEADER: t('Summary'),
    UPDATE_TIME_HEADER: t('Update Time'),
    UPDATED_BY_HEADER: t('Updated By'),
    ALARM_END_DATE_HEADER: t('Alarm End Date'),
    NOT_GET_ALARM_STATUS_LIST: t('Could not get alarm status list'),
    NOT_FETCH_ALARM_LIST: t('Alarm list could not be fetched. Error'),
    NOT_UPDATED_ALARM: t('Alarm could not be updated. Error'),
    ALARM_SUMMARY_PLACEHOLDER: t('Search Alarm Summary'),
    ALARM_STATUS_PLACEHOLDER: t('Select Status'),
    ALARM_TYPE_PLACEHOLDER: t('Select Type'),
    EDIT_ALARM_HEADER: t('Edit Alarm Record'),
    ALARM_STATUS_LABEL: t('Alarm Status'),
    CHANGE_STATUS_PLACEHOLDER: t('Change Status'),
    ALARM_LIST_COULD_NOT_FETCHED: t('Alarm list could not fetched, Error.'),
    FILTER_NA_VALUES: t('Filter N/A values'),
    SUMMARY_COLUMN_VALUE: t(summaryValue)
  };
};

//it is used for pages/usage/ApiUsage.js
export const apiUsageMessages = () => {
  return {
    METHOD_HEADER: t('Method'),
    PATH_HEADER: t('Path'),
    TIME_TAKEN_HEADER: t('Time Taken'),
    REMOTE_ADDRESS_HEADER: t('Remote Address'),
    NOT_FETCH_API_USAGE_HISTORY: t('Could not fetch api usage history'),
    METHOD_PLACEHOLDER: t('Select Method'),
    STATUS_CODE_PLACEHOLDER: t('Select Status Code'),
    PATH_PLACEHOLDER: t('Search Path'),
    REMOTE_ADDRESS_PLACEHOLDER: t('Search Remote Address')
  };
};
//it is used for pages/usage/ReportingPage.js
export const reportingMessages = () => {
  return {
    NUMBER_OF_USERS_MENU_ITEM: t('Number Of Users'),
    TOP_USERS_MENU_ITEM: t('Top Users'),
    USER_RESERVATIONS_MENU_ITEM: t('User Reservations'),
    DEVICE_USAGE_MENU_ITEM: t('Device Usage'),
    RAW_USAGE_DATA_MENU_ITEM: t('Raw Usage Data'),
    INSTANT_USAGE_MENU_ITEM: t('Instant Usage'),
    API_USAGE_MENU_ITEM: t('Api Usage')
  };
};
//it is used for pages/user/LoginPage.js
export const loginMessages = () => {
  return {
    NOT_LOADED_CAPTCHA_ERROR_MESSAGE: t('Captcha could not be loaded!'),
    LOGIN_HEADER: t('Log-in to your account'),
    CAPTCHA_IMAGE_PLACEHOLDER: t('Enter the text above/the answer of the equation'),
    LOGIN_BUTTON: t('Login'),
    NOT_FETCH_ACTIVE_OAUTH2_MESSAGE: t("Can't fetch active oAuth2 list. Error:"),
    NOT_FOUND_OAUTH2_LOGIN_USER: t('OAuth2 login user could not be found'),
    SIGN_IN_WITH_LABEL: t('Sign In With '),
    NOT_FETCH_ACTIVE_GROUP_STATUS_ERROR_MESSAGE: t('Cannot fetch active group information')
  };
};
//it is used for pages/user/ProfilePage.js
export const profileMessages = (key) => {
  return {
    NOT_HAVE_AUTOMATION_KEY_MESSAGE: t('This user does not have an automation key'),
    NOT_FETCH_AUTOMATION_KEY: t('Can not fetch Automation Key. Error: '),
    NOT_HAVE_API_KEY_MESSAGE: t('This user does not have a api key'),
    NOT_FETCH_API_KEY: t('Can not fetch API Key. Error: '),
    NOT_GENERATE_AUTOMATION_KEY: t('Can not generate Automation Key. Error: '),
    NOT_GENERATE_API_KEY: t('Can not generate API Key. Error: '),
    PRIVILEGE_NAME_LABEL: t('Privilege Name'),
    PRIVILEGE_DESCRIPTION_LABEL: t('Privilege Description'),
    TOTAL_NUMBER_OF_PRIVILEGES_LABEL: t('Total Number of Privileges'),
    OLD_PASSWORD_PLACEHOLDER: t('Old Password'),
    NEW_PASSWORD_PLACEHOLDER: t('New Password'),
    CHANGE_PASSWORD_BUTTON: t('Change Password'),
    DOWNLOAD_TXT_BUTTON: t('Download Txt'),
    API_KEY_LABEL: t('API Key'),
    ROLES_AND_PRIVILEGES_HEADER: t('My Roles and Privileges'),
    CHANGE_PASSWORD_HEADER: t('Change Password'),
    AUTOMATION_KEY_HEADER: t('Automation Key'),
    PROFILE_PAGE_HEADER: t('Profile Page'),
    AUTOMATION_KEY_VALUE: t(key),
    API_KEY_VALUE: t(key)
  };
};

//it is used for utils/Constants.js
export const constantsMessages = () => {
  return {
    GET_SESSION_CAPABILITIES_LABEL: t('Get Session Capabilities'),
    SET_PAGELOAD_TIMEOUT_LABEL: t('Set Pageload Timeout'),
    SET_SCRIPT_TIMEOUT_LABEL: t('Set Script Timeout'),
    SET_IMPLICIT_TIMEOUT_LABEL: t('Set Implicit Timeout'),
    GET_ORIENTATION_LABEL: t('Get Orientation'),
    SET_ORIENTATION_LABEL: t('Set Orientation'),
    GET_GEOLOCATION_LABEL: t('Get Geolocation'),
    SET_GEOLOCATION_LABEL: t('Set Geolocation'),
    GET_LOG_TYPES_LABEL: t('Get Log Types'),
    GET_LOGS_LABEL: t('Get Logs'),
    GET_DEVICE_SETTINGS_LABEL: t('Get Device Settings'),
    UPDATE_SETTINGS_LABEL: t('Update Settings'),
    OPEN_NOTIFICATIONS_LABEL: t('Open Notifications'),
    GET_SYSTEM_TIME_LABEL: t('Get System Time'),
    GET_SYSTEM_BARS_LABEL: t('Get System Bars'),
    GET_CLIPBOARD_LABEL: t('Get Clipboard'),
    SET_CLIPBOARD_LABEL: t('Set Clipboard'),
    TOGGLE_AIRPLANE_MODE_LABEL: t('Toggle Airplane Mode'),
    TOGGLE_DATA_LABEL: t('Toggle Data'),
    TOGGLE_WIFI_LABEL: t('Toggle Wifi'),
    TOGGLE_LOCATION_SERVICES_LABEL: t('Toggle Location Services'),
    SHAKE_LABEL: t('Shake'),
    LOCK_LABEL: t('Lock'),
    UNLOCK_LABEL: t('Unlock'),
    IS_LOCKED_LABEL: t('Is Locked'),
    ROTATE_LABEL: t('Rotate'),
    WEB: t('Web'),
    
    NAVIGATE: t('Navigate'),
    PRESS_KEY_CODE_LABEL: t('Press Key Code'),
    LONG_PRESS_KEY_CODE_LABEL: t('Long Press Key Code'),
    HIDE_KEYBOARD_LABEL: t('Hide Keyboard'),
    IS_KEYBOARD_SHOWN_LABEL: t('Is Keyboard Shown'),
    START_ACTIVITY_LABEL: t('Start Activity'),
    CURRENT_ACTIVITY_LABEL: t('Current Activity'),
    CURRENT_PACKAGE_LABEL: t('Current Package'),
    IS_APP_INSTALLED_LABEL: t('Is App Installed'),
    LAUNCH_APP_LABEL: t('Launch App'),
    BACKGROUND_APP_LABEL: t('Background App'),
    CLOSE_APP_LABEL: t('Close App'),
    RESET_APP_LABEL: t('Reset App'),
    REMOVE_APP_LABEL: t('Remove App'),
    ACTIVATE_APP_LABEL: t('Activate App'),
    TERMINATE_APP_LABEL: t('Terminate App'),
    GET_APP_STRINGS_LABEL: t('Get App Strings'),
    END_TEST_COVERAGE_LABEL: t('End Test Coverage'),
    GET_PERFORMANCE_DATA_LABEL: t('Get Performance Data'),
    GET_PERFORMANCE_DATA_TYPES_LABEL: t('Get Performance Data Types'),
    EXECUTE_COMMAND_LABEL: t('Execute Command'),
    GET_CONTEXT_LABEL: t('Get Context'),
    SET_CONTEXT_LABEL: t('Set Context'),
    GET_WINDOW_HANDLE: t('Get Window Handle'),
    CLOSE_WINDOW: t('Close Window'),
    SWITCH_TO_WINDOW: t('Switch To Window'),
    GET_WINDOW_HANDLES: t('Get Window Handles'),
    CREATE_WINDOW: t('Create Window'),
    GO_TO_URL: t('Go To Url'),
    GET_URL: t('Get Url'),
    BACK: t('Back'),
    FORWARD: t('Forward'),
    REFRESH: t('Refresh'),
    GET_ALL_CONTEXT_LABEL: t('Get All Context'),
    HTTP_STATUS_CODE_100: 'HTTP 100 (Continue)',
    HTTP_STATUS_CODE_101: 'HTTP 101 (Switching Protocol)',
    HTTP_STATUS_CODE_102: 'HTTP 102 Processing (WebDAV)',
    HTTP_STATUS_CODE_103: 'HTTP 103 (Early Hints)',
    HTTP_STATUS_CODE_200: 'HTTP 200 (OK)',
    HTTP_STATUS_CODE_201: 'HTTP 201 (Created)',
    HTTP_STATUS_CODE_202: 'HTTP 202 (Accepted)',
    HTTP_STATUS_CODE_203: 'HTTP 203 (Non-Authoritative Information)',
    HTTP_STATUS_CODE_204: 'HTTP 204 (No Content)',
    HTTP_STATUS_CODE_205: 'HTTP 205 (Reset Content)',
    HTTP_STATUS_CODE_301: 'HTTP 301 (Moved Permanently)',
    HTTP_STATUS_CODE_302: 'HTTP 302 (Found)',
    HTTP_STATUS_CODE_303: 'HTTP 303 (See Other)',
    HTTP_STATUS_CODE_304: 'HTTP 304 (Not Modified)',
    HTTP_STATUS_CODE_307: 'HTTP 307 (Temporary Redirect)',
    HTTP_STATUS_CODE_308: 'HTTP 308 (Permanent Redirect)',
    HTTP_STATUS_CODE_400: 'HTTP 400 (Bad Request)',
    HTTP_STATUS_CODE_401: 'HTTP 401 (Unauthorized)',
    HTTP_STATUS_CODE_403: 'HTTP 403 (Forbidden)',
    HTTP_STATUS_CODE_404: 'HTTP 404 (Not Found)',
    HTTP_STATUS_CODE_405: 'HTTP 405 (Method Not Allowed)',
    HTTP_STATUS_CODE_410: 'HTTP 410 (Gone)',
    HTTP_STATUS_CODE_418: 'HTTP 418 (I’m a Teapot)',
    HTTP_STATUS_CODE_429: 'HTTP 429 (Too Many Requests)',
    HTTP_STATUS_CODE_440: 'HTTP 440 (Session Timed Out)',
    HTTP_STATUS_CODE_500: 'HTTP 500 (Internal Server Error)',
    HTTP_STATUS_CODE_501: 'HTTP 501 (Not Implemented)',
    HTTP_STATUS_CODE_502: 'HTTP 502 (Bad Gateway)',
    HTTP_STATUS_CODE_503: 'HTTP 503 (Service Unavailable)',
    HTTP_STATUS_CODE_504: 'HTTP 504 (Gateway Timeout)',
    HTTP_STATUS_CODE_305: 'HTTP 305 (Use Proxy)',
    HTTP_STATUS_CODE_306: 'HTTP 306 (Unused)'
  };
};

//it is used for utils/Method.js
export const methodsMessages = () => {
  return {
    YEARS_LABEL: t('Years'),
    DAYS_LABEL: t('Days'),
    HOURS_LABEL: t('Hours'),
    MINUTES_LABEL: t('Minutes'),
    FAVORITE_DEVICE_LABEL: t('Favorite Device'),
    DEVICE_MARKET_NAME_LABEL: t('Device Market Name'),
    OFFLINE_RESERVED_INFO_LABEL: t('Offline Reserved Info'),
    RESERVED_BY_ME_LABEL: t('Reserved by me'),
    BATTERY_STATUS_LABEL: t('Battery Status'),
    INTERNET_STATUS_LABEL: t('Internet Status'),
    REMOTE_DEBUG_ADDRESS_LABEL: t('Remote Debug Address'),
    MOBILE_DATA_STATUS: t('Mobile Data Status'),
    BUSY_LABEL: t('Busy'),
    TEST_LABEL: t('Test'),
    UNKNOWN_STATUS: t('Unknown Status'),
    HOUR_LABEL: t('Hour'),
    MINUTE_LABEL: t('Minute'),
    SECOND_LABEL: t('Second'),
    MILLISECONDS_LABEL: t('Milliseconds'),
    MILLISECOND_LABEL: t('Millisecond'),
    INSTANTLY_LABEL: t('instantly'),
    CAPTCHA_LABEL: 'Captcha',
    LOGIN_FAILED_LOWER_LIMIT: t('Login Failed Lower Limit'),
    LOGIN_FAILED_UPPER_LIMIT: t('Login Failed Upper Limit'),
    USER_BLOCKED_TIMEOUT: t('User Blocked Timeout'),
    TWO_FACTOR_LOGIN: t('Two Factor Login'),
    LDAP_CONNECTION_TIMEOUT: t('LDAP Connection Timeout'),
    DEVICE_INTERACTION_TIMEOUT: t('Device Interaction Timeout'),
    DEVICE_ACTIVE_BROWSER_WINDOW_TIMEOUT: t('Device Active Browser Window Timeout'),
    CAPTCHA_MODE: t('Captcha Mode'),
    SINGULAR_RESERVATION_LIMIT: t('Singular Reservation Limit'),
    TOTAL_RESERVATION_LIMIT: t('Total Reservation Limit'),
    API_KEY_FAILURE_LIMIT: t('API Key Failure Limit'),
    API_KEY_BLOCK_TIME: t('API Key Block Time'),
    POOL_USER_CONCURRENT_ACCESS_LIMIT: t('Pool User Concurrent Access Limit'),
    LAST_USED_DEVICE_COUNT: t('Last Used Device Count'),
    AVAILABILITY_OF_CHAT_APPLICATION: t('Availability of Chat Application'),
    CHAT_HISTORY_DAY_LIMIT: t('Chat History Day Limit'),
    RESERVATION_DROPPED_TIME: t('Reservation Dropped Time'),
    BUSY_DEVICE_VISIBILITY: t('Visibility of Busy Devices'),
    ALLOW_LOCAL_USER_LOGIN: t('Allow Local User Login'),
    ALLOW_LOCAL_ADMIN_USER: t('Allow Local Admin User'),
    ALLOW_LOCAL_USER_AUTOMATION_KEY_USAGE: t('Allow Local User Automation Key Usage'),
    ALLOW_LOCAL_USER_API_KEY_USAGE: t('Allow Local User API Key Usage'),
    ALLOW_LOCAL_USER_TEST_AUTOMATION: t('Allow Local User Test Automation'),
    ALLOW_TO_USER_USE_RESERVED_DEVICE : t('Allow User To Use Reserved Device'), 
    INTEGER_LABEL: 'Integer',
    BOOLEAN_LABEL: 'Boolean',
    TEXT_LABEL: 'Text',
    MANUAL_TEST_SESSION_STATUS_DONE: t('Done'),
    MANUAL_TEST_SESSION_STATUS_IN_PROGRESS: t('In Progress'),
    MANUAL_TEST_SESSION_STATUS_TIMEOUT: t('Timeout'),
    MANUAL_TEST_SESSION_STATUS_ABORTED: t('Aborted'),
    MANUAL_TEST_SESSION_STATUS_DEVICE_ERROR: t('Device Error'),
    SECOND_SMALL_LABEL: t('second'),
    SECONDS_SMALL_LABEL: t('seconds'),
    APPIUM_LOG_FILE: t('Appium Log File'),
    VIDEO_RECORD_FILE: t('Video Record File'),
    DEVICE_LOG_FILE: t('Device Log File'),
    NETWORK_TRAFFIC_FILE: t('Network Traffic File'),
    SCREENSHOT_FILE: t('Screenshot File'),
    LOCATION_INPUT_NAME_EMPTY_ERROR_MESSAGE: t('Name cannot be empty'),
    LOCATION_INPUT_LATITUTE_ERROR_MESSAGE: t('Latitude must be between -90 and +90'),
    LOCATION_INPUT_LONGITUDE_ERROR_MESSAGE: t('Longitude must be between -180 and +180'),
    ROWS_TEXT: t('rows'),
    NO_DATA_TEXT: t('No rows found'),
    UNACCEPTABLE_FILE_TYPE_ERROR_MESSAGE: t('Unacceptable file type!'),
    CHOOSE_A_FILE_ERROR_MESSAGE: t('Choose a File'),
    MOST_OPTIMAL_IOS_MESSAGE: t('The most optimal -ios class chain could not be determined because an error was thrown'),
    EXTERMINATE_USERS: t('Exterminate the users'),
    DORMANCY_DURATION: t('Define Dormancy Duration'),
    ANDROID_DEVICE_QUALITY: t('Android Devices Default Video Stream Quality'),
    IOS_DEVICE_QUALITY: t('IOS Devices Default Video Stream Quality'),
    DORMANCY_CHECK_EXCLUSION: t('Dormancy Check Exclusions'),
    ALLOW_ANY_NODE_TO_REGISTER: t('Allow Any Node To Register')
  };
};

//it is used src/App.js
export const appMessages = () => {
  return {
    INFORMATION_MODAL_HEADER: t('Information'),
    INFORMATION_MODAL_CONTENT: t('Use Google Chrome for the best VisiumFarm experience'),
    HELP_LABEL: t('Help'),
    ERROR_LABEL: t('Error'),
    HELP_CENTER_MENU_ITEM: t('Help Center'),
    WHAT_IS_NEW_MENU_ITEM: t('What\'s New?'),
    CONTACT_US_MENU_ITEM: t('Contact Us'),
    REMOTE_DEBUGGING_CLIENT_MAC: t('Remote Debugging Client (Mac)'),
    REMOTE_DEBUGGING_CLIENT_WIN: t('Remote Debugging Client (Win)'),
    SETTINGS_MENU_ITEM: t('Settings'),
    ANNOUNCEMENTS_MENU_ITEM: t('Announcements'),
    PROFILE_MENU_ITEM: t('Profile'),
    SIGN_OUT_MENU_ITEM: t('Sign Out'),
    NEW_ANNOUNCEMENT_LABEL: t('NEW ANNOUNCEMENT .'),
    SESSION_TIMEOUT: t('Your Session has timed out')
  }
}


//Busy Devic Publish Modal
export const busyDevicePubModal = user => {
  return {
    CONTENT: `${t('%s wants to use this phone, do you approve?').replace('%s',user)}`,
    HEADER: t('USAGE REQUEST'),
    ALLOW_BUTTON: t('Confirm'),
    REJECT_BUTTON: t('Reject')
  }
}

//it is used for SlaveModal.js
export const NODE_LOG_LOAD_MESSAGE = slave =>(`${slave?.ip?.substring(0, slave?.ip.lastIndexOf(':'))} ${t(' Logs')}`);

//It is used for Feature Promoter Modal
export const promoteFeatureMessages = (innovation) => {
  return {
    PROMOTER_MODAL_HEADER: t('What\'s New?'),
    SHOW_NEW_LABEL_SETTER: t('Show label next to new features'),
    CUSTOM_TITLE: t(innovation?.title),
    CUSTOM_DESCRIPTION: t(innovation?.description),
    CUSTOM_TYPE: t(innovation?.type),
    LETS_START: t('Let\'s Start'),
    ERR_VIEW_VERSION_PROMOTE_MODAL: t('Error while viewing version promoter'),
    ERR_FETCH_VERSION_INOVATIONS: t('Error while fetching version innovations'),
    WELCOME_MSG_PROMOTER_MODAL: t('Welcome'),
    ERR_MODAL_STATUS: t('Cannot fetch version promoter modal status'),
    NO_INNOVATION_MESSAGE: t('There is no version innovation to show.')
  }
}

//it is used for AuthenticationProviders.js
export const authenticationProvidersMesssages = () => {
  return {
    AUTHENTICATION_PROVIDERS_HEADER: t('Authentication Providers'),
    AUTHENTICATION_PROVIDERS_SUB_HEADER: t('Manage all authentication providers'),
    LOCAL_HEADER: t('Local'),
    LDAP_AD_HEADER: t('LDAP/AD'),
    OAUTH2_HEADER: t('OAuth2')
  };
};

//it is used for OAuth2Page.js
export const oAuth2PageMessages = authId => {
  return {
    OAUTH2_SERVERS_HEADER: t('OAuth2 Servers'),
    OAUTH2_SERVERS_SUB_HEADER: t('Add, Edit or Delete OAuth2 servers'),
    ADD_NEW_OAUTH2_SERVER_BUTTON: t('Add New OAuth2 Server'),
    DELETE_CONFIRM: t('Are you sure you want to delete this OAuth2 server config?'),
    OAUTH2_MODAL_HEADER: t('Authentication Source'),
    AUTHENTICATION_NAME_LABEL: t('Authentication Name'),
    TENANT_ID_LABEL: t('Tenant ID'),
    REGISTRATION_ID_LABEL :t('Registration ID'),
    OAUTH2_PROVIDER_LABEL: t('OAuth2 Provider'),
    OAUTH2_ICON_LABEL: t('Authentication Icon'),
    OAUTH_PROVIDER_PLACEHOLDER: t('Select OAuth2 Provider'),
    CLIENT_ID_KEY_LABEL: t('Client ID (Key)'),
    CLIENT_SECRET_LABEL: t('Client Secret'),
    AUTH_PROVIDER_DELETE_SUCCESSFUL: t('Authentication provider %s has been deleted successfully').replace('%s', authId),
    NOT_FETCH_OAUTH2_LIST: t("Can't fetch oAuth2 list. Error:")
  };
};


//Manage Test Integrations
export const integrationMessages = () => {
  return{
    TEST_RUNS_NOT_FETCHED: t('Test runs are not fetched !'),
    MANAGE_PROJECTS: t('Manage Projects'),
    NO_PROJECTS_AVAIBLE: t('Currently there are no projects available'),
    MANAGE_TEST_RUNS: t('Manage Test Runs'),
    TESTER: t('Tester'),
    SUMMARY: t('Summary'),
    TEST_STEPS: t('Test Steps'),
    STEP: t('Step'),
    RESULT: t('Result'),
    COULD_NOT_FETCH_MANAGE_INFO: t('Could not fetch Visium Manage integration information'),
    COULD_NOT_START_EXEC: t('Could not start execution'),
    QUICK_EXEC_HAS_BEEN_EXEC: t('Quick execution has been executed'),
    TEST_RUN_RESULT: t('Test Run Result'),
    CHOOSE_A_STATE: t('Choose a state'),
    BLOCKED: t('Blocked'),
    FAILED: t('Failed'),
    NA: t('N/A'),
    NO_RUN: t('No Run'),
    NOT_COMPLETE: t('Not Complete'),
    PASSED: t('Passed'),
    DEVICE_ID: t('Device Id'),
    SESSION_ID: t('Session Id'),
    SESSION_COULD_NOT_STARTED: t('Session could not started'),
    FAILED_TO_REFRESH: t('Failed to refresh manage tokens'),
    LOG_IN_VISIUM_MANAGE: t('Log in Visium Manage')
  }
}

// Hardware Simulation
export const hardwareSimulateMessages = () => {
  return {
    RES_HEADER: t('Upload Hardware Simulation Resource'),
    RES_DESCRIPTION : t('Upload resources for camera or qr code simulation'),
    RES_QR_CODE: t('QR Code'),
    RES_SUCCESS_UPLOAD: t('Success! Resource uploaded'),
    RES_UNSUCCESSFUL_UPLOAD: t('Unsuccessful! An error occurred while uploading the resource')
  }
}

export const selectedStateMessage = (selectedState) => {
  return t(selectedState);
}

//general headers
export const ID_HEADER = () => t('ID');
export const REPORT_ID_HEADER = () => t('Report ID');
export const DEFECT_URL_HEADER = () => t('Defect URL');
export const DEFECT_CODE = () => t('Defect Code');
export const PROJECT_SUMMARY = () => t('Project Summary');
export const DEVICE_HEADER = () => t('Device');
export const DEVICES_HEADER = () => t('Devices');
export const DEVICE_ID_HEADER = () => t('Device ID');
export const DEVICE_STATUS_HEADER = () => t('Device Status');
export const SESSION_HEADER = () => t('Session');
export const SESSION_ID_HEADER = () => t('Session ID');
export const SESSION_NAME_HEADER = () => t('Session Name');
export const AUTOMATION_TEST_SESSION_HEADER = () => t('Automation Test Sessions');
export const MANUAL_TEST_SESSION_HEADER = () => t('Manual Test Sessions');
export const DEFECT_INSPECTION_HEADER = () => t('Defect Inspection');
export const PREVIOUSLY_OPENED_DEFECTS_HEADER = () => t('Previously Opened Defects');
export const OPERATING_SYSTEM_HEADER = () => t('Operating System');
export const DEVICE_TAG_HEADER = () => t('Device Tag');
export const DEVICE_MODEL_HEADER = () => t('Device Model');
export const BRAND_HEADER = () => t('Brand');
export const MODEL_HEADER = () => t('Model');
export const FAVORITE_DEVICE_HEADER = () => t('Favorite Device');
export const OS_HEADER = () => t('OS');
export const OS_VERSION_HEADER = () => t('OS Version');
export const SECONDARY_SERVERS_HEADER = () => t('Nodes');
export const SECONDARY_SERVER_HEADER = () => t('Node');
export const CLIENT_IP_HEADER = () => t('Client Ip');
export const NODE_IP_HEADER = () => t('Node Ip');
export const MAC_ADDRESS_HEADER = () => t('Mac Address');
export const SCREEN_SIZE_HEADER = () => t('Screen Size');
export const SCREEN_RESOLUTION_HEADER = () => t('Screen Resolution');
export const DEVICE_LIST_HEADER = () => t('Device List');
export const GOOGLE_SERVICE_HEADER = () => t('Google Service');
export const DEVICE_LOGS_HEADER = () => t('Device Logs');
export const APPLICATIONS_HEADER = () => t('Applications');
export const APPS_IN_THIS_DEVICE_HEADER = () => t('Applications In This Device');
export const HOST_HEADER = () => t('Host');
export const PORT_HEADER = () => t('Port');
export const IP_HEADER = () => t('IP');
export const USER_HEADER = () => t('User');
export const USERS_HEADER = () => t('Users');
export const ROLES_HEADER = () => t('Roles');
export const LDAP_SERVERS_HEADER = () => t('Ldap Servers');
export const MAIL_SERVERS_HEADER = () => t('Mail Servers');
export const AGENGT_CONFIGURATION_HEADER = () => t('Agent Configuration');
export const CERTIFICATES_HEADER = () => t('Certificates');
export const USER_MAIL_HEADER = () => t('User Mail');
export const WEBHOOK_HEADER = () => t('Webhook');
export const SYSTEM_PARAMETERS_HEADER = () => t('System Parameters');
export const INTEGRATIONS_HEADER = () => t('Integrations');
export const APP_RESTRICTIONS_HEADER = () => t('Application Restrictions');
export const LOCATIONS_HEADER = () => t('Locations');
export const LOCATION_HEADER = () => t('Location');
export const USER_GROUP_PROPERTIES_HEADER = () => t('User Group Properties');
export const USERNAME_HEADER = () => t('Username');
export const FULLNAME_HEADER = () => t('Full Name');
export const GROUP_NAME_HEADER = () => t('Group Name');
export const GROUP_DESCRIPTION_HEADER = () => t('Group Description');
export const PASSWORD_HEADER = () => t('Password');
export const API_DOCUMENTATION_HEADER = () => t('API Documentation');
export const NAME_HEADER = () => t('Name');
export const DESCRIPTION_HEADER = () => t('Description');
export const ANNOUNCER_HEADER = () => t('Announcer');
export const TARGET_HEADER = () => t('Target');
export const EVENTS_HEADER = () => t('Events');
export const STATUS_HEADER = () => t('Status');
export const BOOKABLE_HEADER = () => t('Bookable');
export const RESERVED_HEADER = () => t('Reserved');
export const FILES_HEADER = () => t('Files');
export const FILE_NAME_HEADER = () => t('File Name');
export const FILE_SIZE_HEADER = () => t('File Size');
export const MANUAL_HEADER = () => t('Manual');
export const AUTOMATION_HEADER = () => t('Automation');
export const DEVELOPMENT_HEADER = () => t('Development');
export const UPLOAD_DATE_HEADER = () => t('Upload Date');
export const UPLOADER_HEADER = () => t('Uploader');
export const DEPLOYMENT_HEADER = () => t('Deployment');
export const LIBRARY_HEADER = () => t('Library');
export const FILE_HEADER = () => t('File');
export const APPLICATION_HEADER = () => t('Application');
export const VERSION_HEADER = () => t('Version');
export const VERSION_CODE_HEADER = () => t('Version Code');
export const ACTIONS_HEADER = () => t('Actions');
export const DEVICE_CARD_CATEGORIES = () => t('Device Categories');
export const AUTOMATION_SETTINGS_HEADER = () => t('Automation Settings');
export const AVAILABILITY_HEADER = () => t('Availability');
export const ASCII_HEADER = () => t('ASCII');
export const JSON_HEADER = () => t('JSON');
export const APP_VERSION_HEADER = () => t('App Version');
export const CATEGORIES_HEADER = () => t('Categories');
export const DOCUMENTATION_ERROR_MESSAGE_HEADER = () => t('Error');
export const INSPECTOR_HEADER = () => t('Inspector');
export const SYSTEM_LOCATIONS_HEADER = () => t('System Locations');
export const MY_LOCATIONS_HEADER = () => t('My Locations');
export const USER_GROUPS_HEADER = () => t('User Groups');
export const TEST_SESSIONS_HEADER = () => t('Test Sessions');
export const USED_HEADER = () => t('Used');
export const EVENT_TIME_HEADER = () => t('Event Time');
export const ALARMS_HEADER = () => t('Alarms');
export const REPORTS_HEADER = () => t('Reports');
export const EXPORT_DEVICE_INFORMATION = () => t('Export Device Information');
export const SECONDARY_SERVER_CONFIG_HEADER = () => t('Node Configuration');
export const AUTO_REGISTER_HEADER = () => t('Auto Register');
export const AUTO_REGISTER_USER_ROLES_LABEL = () => t('Auto Register User Roles');
export const AUTO_REGISTER_USER_GROUPS_LABEL = () => t('Auto Register User Groups');
export const IMAGE_NAME = () => t('Image')

//general label
export const USER_ROLES_LABEL = () => t('User Roles');
export const VERSIONS_LABEL = () => t('Versions');
export const USE_SSL_LABEL = () => t('Use SSL');
export const START_DATE_LABEL = () => t('Start Date');
export const START_TIME_LABEL = () => t('Start Time');
export const END_DATE_LABEL = () => t('End Date');
export const DATE_LABEL = () => t('Date');
export const TIME_LABEL = () => t('Time');
export const USAGE_LABEL = () => t('Usage');
export const APPLICATION_CATEGORY = () => t('Application Category');
export const SELECT_OR_ADD_NEW_CATEGORY = () => t('Select or Add New Category');
export const ACCEPTABLE_FILE_EXTENSION_POPUP = () => t('Acceptable file extensions are');
export const RESERVATIONS_LABEL = () => t('Reservations');
export const RESERVATION_LABEL = () => t('Reservation');
export const DEVICE_DETAILS_LABEL = () => t('Device Details');
export const ADD_NEW_LOCATION_LABEL = () => t('Add New Location');
export const LOCATION_NAME_LABEL = () => t('Location Name');
export const DETAILS_LABEL = () => t('Details');
export const ACTIVE_LABEL = () => t('Active');
export const PASSIVE_LABEL = () => t('Passive');
export const UNKNOWN_LABEL = () => t('Unknown');
export const NO_NAME_LABEL = () => t('No Name');
export const CATEGORY_LABEL = () => t('Category');
export const SELECTOR_LABEL = () => t('Selector');
export const TOTAL_LABEL = () => t('Total');
export const MANUAL_LABEL = () => t('Manual');
export const AUTOMATION_LABEL = () => t('Automation');
export const DEVELOPMENT_LABEL = () => t('Development');
export const TYPE_LABEL = () => t('Type');
export const YES_LABEL = () => t('Yes');
export const NO_LABEL = () => t('No');
export const OTHER_LABEL = () => t('Other');
export const LATITUDE_LABEL = () => t('Latitude');
export const ALTITUDE_LABEL = () => t('Altitude');
export const LONGITUDE_LABEL = () => t('Longitude');
export const ASK_LABEL =() => t('Ask')
export const GROUP_ONLY =() => t('Group Only')
export const DEVICE_INFORMATION_CLIPBOARD_POPUP_MESSAGE = () => t('Device Information Clipboard');
export const CONNECTION_TERMINATED_MESSAGE = () => t('Connection Terminated');
export const TERMINATE_CONNECTION_MESSAGE = () => t('Terminate Connection');
export const CONNECTION_SUCCESSFULL_MESSAGE = () => t('Connection Successful');
export const NOTHING_HERE_MESSAGE = () => t('There is nothing here');
export const SEARCH_PLACEHOLDER = () => t('Search...');
export const SEARCH_APPLICATION_PLACEHOLDER = () => t('Search Application');
export const SEARCH_ID_PLACEHOLDER = () => t('Search ID');
export const SEARCH_ROLE_PLACEHOLDER = () => t('Search By User Role');
export const SEARCH_SESSION_NAME_PLACEHOLDER = () => t('Search Session Name');
export const SEARCH_USER_PLACEHOLDER = () => t('Search User');
export const UPLOAD_NEW_APP_LABEL = () => t('Upload New Application');
export const UPLOAD_NEW_FILE_LABEL = () => t('Upload New File');
export const NOT_FETCH_USERS = () => t("Can't fetch users. Error ");
export const NOT_FETCH_DEVICE_DETAILS = () => t("Can't fetch device details. Error Code:");
export const NOT_FETCH_DEVICE_LIST = () => t('Device list could not fetched. Error Code ');
export const NOT_FETCH_APPS = () => t('Cant fetch Apps. Error ');
export const NOT_FETCH_DEVICES = () => t("Can't fetch devices. Error ");
export const NOT_FETCH_DEFECTS = () => t('Defects could not be fetched');
export const NOT_FETCH_GROUP_USERS = () => t('Can not fetch group users. Error');
export const NOT_FETCH_ROLES_ERROR_MESSAGE = () => t("Can't fetch roles from server: ");
export const NOT_FETCH_SYSTEM_PARAMETERS_ERROR_MESSAGE = () => t("Can't fetch system parameters from server ");
export const NOT_TAKE_SCREENSHOT = () => t('Screenshot could not take. Status Code');
export const NOT_DOWNLOAD_REPORTS = () => t("Can't download reports. Error  ");
export const NOT_GET_LOCATION_LIST = () => t('Failed to get location list');
export const ERROR_CODE_MESSAGE = () => t('Error Code');
export const OFFLINE_LABEL = () => t('Offline');
export const ONLINE_LABEL = () => t('Online');
export const ONLINE_FREE_LABEL = () => t('Online / Free');
export const MAINTENANCE_LABEL = () => t('Maintenance');
export const FREE_LABEL = () => t('Free');
export const RESERVED_BY_LABEL = () => t('Reserved by');
export const GROUP_LABEL = () => t('Group');
export const RESERVED_LABEL = () => t('Reserved');
export const TEST_INFO_LABEL = () => t('Test Info');
export const NEXT_LABEL = () => t('Next');
export const PREVIOUS_LABEL = () => t('Previous');
export const PAGE_LABEL = () => t('Page');
export const TODAY_LABEL = () => t('Today');
export const EVERY_DAY_LABEL = () => t('every day');
export const EVERY_MONTH_LABEL = () => t('every month');
export const MONTH_LABEL = () => t('Month');
export const WEEK_LABEL = () => t('Week');
export const DAY_LABEL = () => t('Day');
export const DAY_SMALL_LABEL = () => t('day');
export const DAYS_SMALL_LABEL = () => t('days');
export const HOUR_LABEL = () => t('hour');
export const HOURS_LABEL = () => t('hours');
export const MINUTE_LABEL = () => t('minute');
export const MINUTES_LABEL = () => t('minutes');
export const COPY_TO_CLIPBOARD_MESSAGE = () => t('Copy to clipboard');
export const COPY_CODE_TO_CLIPBOARD_MESSAGE = () => t('Copy code to clipboard');
export const UNSUPPORTED_TOKEN_TYPE_ERROR = () => t('Unsupported Token Type');
export const COPIED_LABEL = () => t('Copied!');
export const SECONDS_LABEL = () => t('Seconds');
export const RETURN_TO_DEVICE_LIST_LABEL = () => t('Return to device list');
export const N_A_LABEL = () => t('N/A');
export const NONE_LABEL = () => t('None');
export const NO_DATA_LABEL = () => t('No Data');
export const KEY_LABEL = () => t('Key');
export const VALUE_LABEL = () => t('Value');
export const SIMPLE_RATIO_LABEL = () => t('Simple Ratio');
export const TEST_TYPE_LABEL = () => t('Test Type');
export const FAVORITE_LABEL = () => t('Favorite');
export const UNFAVORITE_LABEL = () => t('Unfavorite');
export const CONCURRENT_ACCESS_LIMIT_LABEL = () => t('Concurrent Access Limit');
export const REMOVE_DEVICE_FROM_FAVORITE_MESSAGE = () => t('Device removed from favorites');
export const ADD_DEVICE_TO_FAVORITE_MESSAGE = () => t('Device added to favorites');
export const NOT_STREAMED_DATA_MESSAGE = () => t("Can't streamed data");
export const GET_REMOTE_INFO_ERROR = () => t('Encountered error while getting remote information');
export const TAKING_SCREENSHOTS_LOADER = () => t('Taking Screenshots');
export const MARKER_IS_DRAGGABLE_LABEL = () => t('Marker is draggable');
export const CLICK_TO_MAKE_MARKER_DRAGGABLE_LABEL = () => t('Click here to make marker draggable');
export const TOTAL_NUMBER_OF_DEVICES_LABEL = () => t('Total Number of Devices');
export const TOTAL_NUMBER_OF_APPLICATIONS_LABEL = () => t('Total Number of Applications');
export const URL_LABEL = () => t('URL');
export const NOT_FETCH_APPLICATIONS_ERROR_MESSAGE = () => t('Can not fetch applications. Error: ');
export const NODE_LABEL = () => t('Node')
export const NODE_INFO_LABEL = () => t('Node Info')

//general buttons
export const SHOW_BUTTON = () => t('Show');
export const SHOW_MORE_BUTTON = () => t('Show More');
export const SHOW_LESS_BUTTON = () => t('Show Less');
export const HIDE_BUTTON = () => t('Hide');
export const CLOSE_BUTTON = () => t('Close');
export const UPDATE_BUTTON = () => t('Update');
export const SAVE_BUTTON = () => t('Save');
export const ADD_BUTTON = () => t('Add');
export const CONFIRM_BUTTON = () => t('Confirm');
export const CANCEL_BUTTON = () => t('Cancel');
export const CHANGE_BUTTON = () => t('Change');
export const DOWNLOAD_BUTTON = () => t('Download');
export const EDIT_BUTTON = () => t('Edit');
export const DELETE_BUTTON = () => t('Delete');
export const UPLOAD_BUTTON = () => t('Upload');
export const CLEAR_BUTTON = () => t('Clear');
export const GENERATE_BUTTON = () => t('Generate');
export const RECONNECT_BUTTON = () => t('Reconnect');
export const OK_BUTTON = () => t('OK');
export const RESET_TABLE_BUTTON = () => t('Reset Table');
export const SEARCH_BUTTON = () => t('Search');
export const SELECT_ALL_BUTTON = () => t('Select All');
export const REMOVE_ALL_BUTTON = () => t('Remove All');
export const DEPLOY_BUTTON = () => t('Deploy');
export const DEFECT_BUTTON = () => t('Defect');
export const OPEN_NEW_DEFECT_BUTTON = () => t('Open New Defect');
export const GO_TO_DEFECT_BUTTON = () => t('Go to Defect');
export const COPY_BUTTON = () => t('Copy');
export const CONNECT_BUTTON = () => t('Connect');
export const SEND_KEYS_BUTTON = () => t('Send Keys');
export const BACK_BUTTON = () => t('Back');
export const DONE_BUTTON = () => t('Done');
export const SUBMIT_BUTTON = () => t('Submit');
export const TEST_BUTTON = () => t('Test');
export const DOWNLOAD_TABLE_AS_CSV = () => t('Download this table as CSV file');

export const LOADING = () => t('Loading...');
export const SUCCESS_MESSAGE = () => t('Success');
export const UNKNOWN_ERROR = () => t('Unknown Error');
export const FAILED_ERROR_MESSAGE = () => t('Failed. Error ');
export const FAILED_ERROR_CODE_MESSAGE = () => t('Failed. Error Code ');

export const CHANGE_ACTIVE_GROUP = () => t('Change Active Group');
export const PUBLIC = () => t('Public');
export const NEW_LABEL = () => t('New');

// Hardware Simulation
export const HARDWARE_SIMULATION_RES_HEADER =()=> t('Upload Hardware Simulation Resource')
export const HARDWARE_SIMULATION_RES_DESCRIPTION = ()=> t('Upload photo for camera simulate')


export const VIDEO_PLAYER = () =>{
  return {
    VIDEO_ERROR: t('Your browser does not support HTML video!'),
    VIDEO_NOT_FOUND : t('Video not found!'),
    INVALID_VIDEO_SRC : t('Invalid video source!')
  }
}
